import React from "react";

const About = () => {
  return (
    <section className="bg-slate-100 px-4 py-16 md:py-20 md:px-10">
      <h1 className="text-[#1a5252] flex justify-center items-center uppercase text-4xl font-extrabold pb-10">
        About Us
      </h1>
      <div className="flex flex-col md:flex-row gap-6 md:gap-10">
        {/* Image Section */}
        <div className="w-full md:w-1/2">
          <img
            className="rounded-2xl w-full"
            src="https://img.freepik.com/premium-photo/man-is-using-laptop-with-sign-that-says-marketing-marketing_1270349-31949.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
            alt="Digital marketing concept"
          />
        </div>

        {/* Text Content Section */}
        <div className="flex flex-col justify-center w-full md:w-1/2 sm:pl-2 pt-8 md:pt-0">
          <h2 className="text-[#1a5252] text-2xl sm:text-4xl font-bold uppercase">
            We Always Make The Best
          </h2>
          <p className="text-gray-500 py-4 sm:py-6 text-sm sm:text-base leading-relaxed">
            At Get to Peaks, we are a team of passionate digital marketing professionals dedicated to helping businesses succeed online. Our expertise spans multiple digital disciplines, including{" "}
            <span className="font-bold text-[#1a5252]">Web Development, App Development, SEO, Graphic Design,</span> and{" "}
            <span className="font-bold text-[#1a5252]">WordPress Development.</span> Our mission is to elevate your business through cutting-edge web development, creative design, and strategic SEO efforts. Focusing on creating unique, high-quality digital experiences, we work closely with our clients to bring their vision to life.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
