import React from "react";
import GraphicDesignAdvantage from "./GraphicDesignAdvantage";
// import ShopifyStoreAdvantage from "./ShopifyStoreAdvantage";

const GDAbout = () => {
  return (
    <>
      <div className="pt-14 flex flex-col-reverse md:flex-row justify-around px-4 md:pt-24 md:px-10 bg-slate-100">
        {/* Embed CSS for animation */}

        <div className="w-full md:w-[49%] flex  flex-col justify-center text-start pt-2">
          <h1 className="text-[#1a5252] uppercase text-4xl md:text-4xl pb-1 font-bold relative inline-block">
          Graphic Design
          </h1>
          <p className="py-6 text-gray-400 ">
          At Get to Peaks, we offer top-tier Graphic Designing Services in the UK tailored to elevate your brand’s identity and visual communication. Our creative team brings innovation, strategy, and attention to detail to every project, ensuring that your designs are not only aesthetically pleasing but also aligned with your brand values and business goals.We believe that effective design goes beyond visuals; it tells your story and engages your audience. Whether you need stunning logos, eye-catching marketing materials, or comprehensive branding solutions, we’re dedicated to crafting unique designs that resonate with your target market and drive results.
          </p>
        </div>

        <div className="w-full md:w-[49%] p-3 mb-6 md:mb-0">
          <div className="grid grid-cols-1 gap-4">
            <img
              className="rounded-2xl w-full sm:h-[300px]"
              src="https://img.freepik.com/free-photo/man-editing-photos-computer_53876-46864.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
              alt="Top image"
            />
          </div>
        </div>
      </div>
      < GraphicDesignAdvantage/>
    </>
  );
};

export default GDAbout;
