import React from "react";
import PackagePricing from "./PackagePricing";

const Package = () => {
  return (
    <>
      <div
        className="text-center bg-cover bg-center bg-no-repeat relative"
        style={{
          backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEi-tYq1BQQ-g0wZ2FQ1wcqd4oAj9xuMGyc0t0e5_SlKVxA6vzhvRor2-9gi9Flah7u7bLcim6iugaGhBSzVKYUl82zt4WBWmnhWZsIp_J2fXYGldpjIngdY_HTvXprsweB429P3jvcclVOhEFjqpOArKUlsj0ndT0Dd37XTz369HPX2MCsgFLzCnVbT71Xh/w1684-h1069-p-k-no-nu/APP%20(1).png")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          minHeight: "100vh",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* Semi-transparent overlay for better text visibility */}
        <div className="absolute inset-0  opacity-50"></div>
        <div className="flex flex-col items-center justify-center min-h-screen relative z-10">
          <h1 className="text-5xl sm:text-6xl font-bold pt-28 text-white">
            Business Plan
          </h1>
          {/* Uncomment if you want to include a subtitle */}
          {/* <p className="sm:text-xl pt-1 px-6 sm:px-56 text-white">
            A picture speaks a thousand words. Our graphic designers create
            striking visuals that not only represent your brand but also leave
            a lasting impression on your audience.
          </p> */}
        </div>
      </div>
      <PackagePricing />
    </>
  );
};

export default Package;
