import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa"; // Importing tick and cross icons

const VideoEditingPricing = () => {
  const plans = [
    {
      title: "Basic",
      price: "$150",
      subtitle:
        "Beautuful yet simple I,ll work on your video with simple tools. 1 revision containing: editing and basic titles.",
      features: [
        {
          text: "Up to 15 minutes of footage provided",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Up to 5 minutes running time",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Color grading",
          icon: (
            <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Subtitles",
          icon: (
            <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Motion graphics",
          icon: (
            <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Sound design & mixing",
          icon: (
            <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" />
          ),
        },
      ],
      buttonText: "Get Started",
      buttonLink: "/basic-plan",
      bgColor: "bg-white",
      textColor: "text-gray-800",
    },
    {
      title: "Standard",
      price: "$250",
      subtitle:
        "A touch of style Ill customize your video, Ill add motion graphics, animated titles and color grading",
      features: [
        {
          text: "Up to 30 minutes of footage provided",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Up to 15 minutes running time",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Color grading",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Subtitles",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Motion graphics",
          icon: (
            <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Sound design & mixing",
          icon: (
            <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" />
          ),
        },
      ],
      buttonText: "Get Started",
      buttonLink: "/standard-plan",
      bgColor: "bg-gray-100",
      textColor: "text-gray-800",
    },
    {
      title: "Premium",
      price: "$350",
      subtitle:
        "The art of Crafting You ll receive a complete customized service according to your needs. Always contact me first",
      features: [
        {
          text: "Up to 15 minutes of footage provided",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Up to 5 minutes running time",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Color grading",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Subtitles",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Motion graphics",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
        {
          text: "Sound design & mixing",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
      ],
      buttonText: "Get Started",
      buttonLink: "/premium-plan",
      bgColor: "bg-white",
      textColor: "text-gray-800",
    },
  ];

  return (
    <div className="py-8">
      <h2 className="text-4xl font-bold text-center mb-8">Pricing Plan</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-0 px-10">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`${plan.bgColor} ${plan.textColor} shadow-lg border-2 border-[#1a5252] rounded-3xl p-4 flex flex-col transition-transform duration-3 scale-95`}
          >
            <h3 className="text-2xl font-bold mb-1">{plan.title}</h3>
            <div className="flex-grow">
              <p className="text-2xl font-bold mb-1">{plan.price}</p>
              <p className="text-sm text-gray-500 mb-3">{plan.subtitle}</p>
              <ul className="text-sm flex-grow p-0 m-0">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center mb-2">
                    {feature.icon}
                    <span className="ml-2">{feature.text}</span>
                  </li>
                ))}
              </ul>
            </div>
            <a
              href="https://wa.me/+447999568729"
              target="_blank"
              className="bg-[#1f6969] text-white font-bold py-2 rounded-full mt-4 text-center transition duration-300 hover:bg-[#1a5252]"
            >
              {plan.buttonText}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoEditingPricing;
