import React, { useState, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleMenu = () => setNavOpen((prev) => !prev);
  const closeMenus = () => {
    setNavOpen(false);
    setDropdownOpen(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 50);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const navLinkClass = `transition-colors duration-300 ${
    isScrolled ? "text-[#1a5252]" : "text-white"
  }`;

  return (
    <header className={`fixed w-full top-0 z-50 transition-all duration-300 ${isScrolled ? "bg-white shadow-md" : "bg-transparent"}`}>
      <div className="flex justify-between items-center px-5 md:px-32 py-5">
        <Link to="/" onClick={closeMenus}>
          <img className="w-14 h-14" src="/Images/logo/get.png" alt="logo" />
        </Link>

        <nav className="hidden md:flex items-center gap-12 text-lg font-bold">
          <Link to="/" className={navLinkClass} onClick={closeMenus}>Home</Link>
          <Link to="/about" className={navLinkClass} onClick={closeMenus}>About</Link>

          <div
            className="relative"
            onMouseEnter={() => setDropdownOpen(true)}
            onMouseLeave={() => setDropdownOpen(false)}
          >
            <Link to="/services" className={navLinkClass} onClick={closeMenus}>Services</Link>
            {dropdownOpen && (
              <div className="absolute left-0 mt-0 w-[200px] bg-black text-sm text-white rounded-lg shadow-lg border-2 border-[#1a5252] z-50">
                {[
                  { path: "/webdevelopment", label: "Web Development" },
                  { path: "/appdevelopment", label: "App Development" },
                  { path: "/wordpressdevelopment", label: "Wordpress Development" },
                  { path: "/seo", label: "SEO Services" },
                  { path: "/graphicdesign", label: "Graphic Design" },
                  { path: "/socialmedia", label: "Social Media Marketing" },
                  { path: "/shopifystore", label: "Shopify Store" },
                  { path: "/virtualassistant", label: "Virtual Assistant" },
                  { path: "/videoediting", label: "Video Editing" }
                ].map(({ path, label }) => (
                  <Link
                    key={path}
                    to={path}
                    className="block px-4 py-2 hover:text-[#1a5252]"
                    onClick={closeMenus}
                  >
                    {label}
                  </Link>
                ))}
              </div>
            )}
          </div>

          <Link to="/blog" className={navLinkClass} onClick={closeMenus}>Blog</Link>
          <Link to="/contact" className={navLinkClass} onClick={closeMenus}>Contact</Link>
          <Link to="/package">
            <button className="bg-[#1a5252] text-white py-2 px-5 rounded-full font-semibold">Business Plan</button>
          </Link>
        </nav>

        <button onClick={toggleMenu} className="md:hidden text-black">
          {navOpen ? <AiOutlineClose size={25} className="text-[#1a5252]" /> : <AiOutlineMenu size={25} />}
        </button>
      </div>

      {/* Mobile Menu */}
      <div
        className={`${
          navOpen ? "translate-x-0" : "-translate-x-full"
        } md:hidden flex flex-col items-center bg-[#1a5252] text-white absolute top-0 left-0 h-screen py-24 w-full transition-transform duration-300 ease-in-out z-40`}
      >
        <button onClick={toggleMenu} className="absolute top-5 right-5 text-white">
          <AiOutlineClose size={25} />
        </button>

        {["Home", "About", "Services", "Blog", "Contact", "Business Plan"].map((label, idx) => (
          <Link
            key={idx}
            to={`/${label.toLowerCase().replace(" ", "")}`}
            className="font-bold text-2xl py-4"
            onClick={closeMenus}
          >
            {label}
          </Link>
        ))}
        {dropdownOpen && (
          <div className="absolute left-0 mt-2 w-48 bg-[#598989] text-white rounded-lg shadow-lg z-50">
            {[
              { path: "/web-development", label: "Web Development" },
              { path: "/app-development", label: "App Development" },
              { path: "/graphic-design", label: "Graphic Design" },
              { path: "/seo", label: "SEO Services" },
              { path: "/smm", label: "Social Media Marketing" },
              { path: "/content-writing", label: "Content Writing" },
              { path: "/video-editing", label: "Video Editing" },
              { path: "/consulting", label: "Consulting" }
            ].map(({ path, label }) => (
              <Link key={path} to={path} className="block px-4 py-2 hover:text-[#1a5252]" onClick={closeMenus}>
                {label}
              </Link>
            ))}
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;
