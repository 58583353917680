import React from "react";

const Work = () => {
  return (
    <div className="w-full bg-[#1a5252] flex justify-center items-center flex-col py-12 px-4 md:px-8 text-center">
      {/* Embed CSS for animation */}
      <style>
        {`
          @keyframes underline-animation {
            0% { width: 0; }
            50% { width: 100%; }
            100% { width: 0; }
          }
          .underline-animation {
            width: 0;
            height: 4px;
            background-color: #ffffff;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            animation: underline-animation 8s infinite;
          }
        `}
      </style>

      <h1
        id="our"
        className="text-white text-xl sm:text-4xl pb-4 font-bold relative inline-block"
      >
        Adaptable Strategies for All Industries
        <span className="block absolute bottom-0 left-1/2 mt-1 underline-animation"></span>
      </h1>
      <p className="text-white text-[14px] md:text-lg md:px-40 lg:px-60 pt-2 pb-5">
      businesses are the same, and neither are their digital marketing needs. At GetToPeaks, we take pride in creating custom strategies that cater to different industries, whether you’re in e-commerce, hospitality, healthcare, or any other sector. Our approach is flexible and adaptable, ensuring that your business gets the attention and strategy it deserves, no matter the industry.
      </p>
      <a
        href="https://wa.me/+447999568729"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="bg-white text-[#1a5252] font-bold py-2 px-6 md:px-8 rounded-full">
          Contact Us
        </button>
      </a>
    </div>
  );
};

export default Work;
