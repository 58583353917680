import React from "react";
import { FaUsers, FaHeadset, FaLightbulb, FaDollarSign } from "react-icons/fa";

const ChooseUs = () => {
  // Define keyframes for the underline animation
  const keyframesStyle = `
    @keyframes underlineAnimation {
      0% { width: 0; }
      50% { width: 100%; }
      100% { width: 0; }
    }
  `;

  // Define the cards data with titles, descriptions, and icons
  const cards = [
    {
      title: "Expert Team",
      description:
        "An Expert Team is a group of specialists with advanced skills working together to deliver high-quality, efficient solutions.",
      icon: <FaUsers size={30} />,
    },
    {
      title: "Customer Support",
      description:
        "Customer Support provides 24/7 assistance to ensure quick, effective solutions, enhancing client satisfaction and a seamless experience.",
      icon: <FaHeadset size={30} />,
    },
    {
      title: "Innovation",
      description:
        "We bring innovative solutions to the table, constantly pushing the boundaries of what's possible.",
      icon: <FaLightbulb size={30} />,
    },
    {
      title: "Affordable Prices",
      description:
        "We offer competitive pricing without compromising on quality, ensuring value for your investment.",
      icon: <FaDollarSign size={30} />,
    },
  ];

  return (
    <section className="bg-[#1a5252] w-full py-14 px-10 md:py-24 md:px-24">
      {/* Embed the keyframes style */}
      <style>{keyframesStyle}</style>

      {/* Section title */}
      <div className="text-center mb-10">
        <h1 className="text-white text-3xl md:text-4xl font-bold uppercase relative pb-2">
          Why Choose Us
        </h1>
      </div>

      {/* Cards grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pt-6">
        {cards.map((card, index) => (
          <div
            key={index}
            className="bg-opacity-20 bg-white p-6 text-white rounded-lg shadow-lg flex flex-col items-center text-center transition-transform hover:scale-105"
          >
            <div className="mb-4">{card.icon}</div>
            <h2 className="text-xl font-semibold mb-2">{card.title}</h2>
            <p className="text-sm">{card.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ChooseUs;
