import React from "react";
import { FaCheck } from "react-icons/fa"; // Importing check icon

const SMMAdvantage = () => {
  // Service list data
  const services = [
    {
        title: "Social Media Strategy Development",
        description: "A strong social media presence starts with a well-thought-out plan. We craft custom social media strategies tailored to your business goals, target audience, and brand identity. This includes:",
        bold: "• Platform Selection:",
        description1: "Identifying the right platforms (Facebook, Instagram, LinkedIn, Twitter, TikTok, etc.) where your audience is most active.",
        bold1: "• Content Planning:",
        description2: "Developing a content calendar that aligns with your marketing objectives, highlighting key campaigns, events, and brand messages.",
        bold2: "• Competitive Analysis:",
        description3: "Analyzing your competitors’ social media activities to discover opportunities for differentiation and growth."
    },
    {
        title: "Content Creation & Management",
        description: "Content is the heart of any successful social media strategy. Our creative team produces high-quality, engaging content that captures your brand’s voice and drives interaction. We offer:",
        bold: "• Custom Graphics and Videos:",
        description1: "Eye-catching visuals, videos, and animations that stand out in a crowded feed.",
        bold1: "• Post Scheduling and Publishing:",
        description2: "Consistent posting across platforms, optimized for the best times to reach your audience.",
        bold2: "• Storytelling:",
        description3: "Crafting posts that tell your brand’s story and build connections with your followers through creative and authentic messaging."
    },
    {
        title: "Social Media Advertising",
        description: "In 2024, paid advertising on social platforms is more critical than ever. We create targeted social media ads that reach the right people, drive conversions, and generate ROI. Our services include:",
        bold: "• Ad Creation and Design:",
        description1: "Designing attention-grabbing ads that align with your brand and messaging.",
        bold1: "• Audience Targeting:",
        description2: "Leveraging social media’s powerful targeting features to reach your ideal customers based on demographics, interests, and behaviors.",
        bold2: "• Campaign Optimization:",
        description3: "Monitoring and adjusting your ad campaigns in real-time to maximize performance and minimize costs."
    },
    {
        title: "Social Media Analytics and Reporting",
        description: "Understanding the impact of your social media efforts is essential for ongoing success. We track and analyze key performance metrics to provide actionable insights into your campaign’s effectiveness. Our services include:",
        bold: "• Detailed Reports:",
        description1: "Monthly reports highlighting reach, engagement, and conversions, along with insights into what’s working and what needs improvement.",
        bold1: "• Data-Driven Adjustments:",
        description2: "Using analytics to refine your strategy, ensuring that your social media presence continuously improves and delivers results."
    },
    {
        title: "Community Management",
        description: "Building a loyal online community is vital for brand success in 2024. Our team manages your online community, interacting with your audience and ensuring your brand stays responsive and approachable. We handle:",
        bold: "• Comment and Message Responses:",
        description1: "Engaging with followers in a timely manner, answering questions, and providing support.",
        bold1: "• Reputation Management:",
        description2: "Addressing negative feedback and reviews constructively to maintain a positive brand image.",
        bold2: "• Influencer Outreach:",
        description3: "Identifying and collaborating with influencers who can promote your brand and amplify your reach."
    },
    {
        title: "Social Commerce Integration",
        description: "2024 is the year of social shopping. We help businesses tap into the growing trend of social commerce, where users can browse, shop, and purchase directly from their favorite social platforms. Our services include:",
        bold: "• Product Tagging and Shoppable Posts:",
        description1: "Making your posts and stories fully shoppable for seamless customer experiences.",
        bold1: "• Optimizing Social Storefronts:",
        description2: "Creating and managing storefronts on platforms like Instagram and Facebook to showcase your products.",
        bold2: "• Social Checkout Management:",
        description3: "Streamlining the purchase process directly through social media to boost sales and engagement."
    }
];

  
  
  // Advantages data
  const advantages = [
    {
        title: "Expert Knowledge of Trends: ",
        description: "Our team stays ahead of social media trends, ensuring your brand uses the latest features and strategies to stand out."
    },
    {
        title: "Comprehensive Service Offering: ",
        description: "From content creation to advertising and analytics, we provide a full range of services to cover all aspects of social media marketing."
    },
    {
        title: "Customized Approach: ",
        description: "We tailor our strategies to match your unique brand identity and goals, creating personalized content and campaigns for maximum impact."
    },
    {
        title: "Proven Results: ",
        description: "With our data-driven approach and experience, we deliver measurable outcomes that grow your followers, drive engagement, and increase conversions."
    },
    {
        title: "Innovative and Creative Team: ",
        description: "Our creative team excels in producing compelling, visually stunning content that resonates with your audience and turns followers into customers."
    }
];




  return (
    <div className="bg-gray-100 pb-14 px-6 md:py-24 md:px-12 lg:px-16">
      {/* Header Section */}
      <div className="text-center mb-12">
        <div className="flex flex-col  w-[100%] lg:flex-row py-4 gap-5 ">
          <img
            className="w-full  lg:w-96  sm:rounded-tr-full  "
            src="https://img.freepik.com/free-photo/close-up-businesspeople-with-digital-tablet-social-media-icons-table_23-2147847546.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
            alt="WordPress Website Design"
          />
          <div>

        <h1 className="text-[#1a5252] uppercase text-3xl sm:text-4xl pb-1 font-bold relative inline-block">
        Whats New in 2024 for Social Media Marketing?
        </h1>
          <p className="pt-2 text-start sm:text-center md:px-12 text-gray-500">
          Social media marketing continues to evolve, and 2024 brings fresh opportunities for businesses to leverage new tools, trends, and strategies. With the rise of short-form video, social commerce, and AI-powered insights, brands must stay agile to keep up with the competition. Our team stays on top of these developments to create social media campaigns that resonate with your audience and deliver tangible results.
          </p>
          </div>
        </div>
      </div>

      {/* Our video editing Services Section */}
      <div className="text-start mb-12">
        <h1 className="text-[#1a5252] uppercase text-3xl font-bold mb-4">
        Our Graphic Designing Services Include:
        </h1>

        {/* Service List */}
        {services.map((service, index) => (
          <div key={index} className="pt-10">
            <h2 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold pb-1">
              {index + 1}. {service.title}
            </h2>
            <p className="pt-3 text-gray-500 sm:pr-56">{service.description}</p>
            <div className="">
            <p className="pt-8 text-gray-500">
              <span className="font-bold text-black"> {service.bold}</span>{" "}
              {service.description1}
            </p>
            <p className="pt-8 text-gray-500">
              <span className="font-bold text-black"> {service.bold1}</span>{" "}
              {service.description2}
            </p>
            <p className="pt-8 text-gray-500">
              <span className="font-bold text-black"> {service.bold2}</span>{" "}
              {service.description3}
            </p>
            </div>
          </div>
        ))}
      </div>

      {/* Why Choose Us Section */}
      <div className="">
        <h1 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold mb-4">
        Why Choose Get to Peaks for Social Media Marketing in 2024?
        </h1>
        <div className="sm:pr-64">
          {advantages.map((advantage, index) => (
            <p key={index} className="text-lg text-start pt-2 text-gray-500">
              <span className="font-bold text-black">• {advantage.title}</span>{" "}
              {advantage.description}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SMMAdvantage;
