import React from "react";
import VirtualAssistantAbout from "./VirtualAssistantAbout";
import VirtualAssistantBox from "./VirtualAssistantBox";
import VirtualAssistantAdvantage from "./VirtualAssistantAdvantage";
import VirtualAssistantPricing from "./VirtualAssistantPricing";


const SMM = () => {
  return (
    <>
      <div
        className="text-center bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgY-uiSr81ulTxIqFv3fQ6c3PzUygBhsL71nUUzNra7VjEv-76DXjk10oSAFQQ6s1yqZoKsGcgde-cfdJeag7wmVPeL8CxrXxOod_k8pbbJY2zNH6eUdfu3lciLzx4ZHqc_PmSZebTsG8smvD5eJd5tT5GyTzDnqktFBh-qne4wa6qMBrBN7Dtbrh5buwuD/w1684-h1069-p-k-no-nu/APP%20(1).png")`,
          backgroundSize: "cover", // Ensures the image covers the entire container
          backgroundPosition: "center",
          backgroundAttachment: "fixed", // Keeps the image fixed while scrolling
          minHeight: "100vh", // Ensures the container is at least full viewport height
          backgroundRepeat: "no-repeat", // Prevents repeating the image
        }}
      >
        <div className="flex flex-col items-center justify-center min-h-screen">
          <h1 className="text-4xl sm:text-6xl font-bold pt-28 text-white">Virtual Assistant</h1>
          <p className="sm:text-xl pt-1 px-6 sm:px-56 text-white">
          Our Virtual Assistance services handle administrative tasks like scheduling, email management, and data entry, allowing you to focus on growing your business efficiently.
          </p>
        </div>
      </div>
    <VirtualAssistantPricing />
    <VirtualAssistantAbout />
    <VirtualAssistantBox />
    {/* <VirtualAssistantAdvantage /> */}
    </>
  );
};

export default SMM;
