import React from 'react';
import VirtualAssistantAdvantage from './VirtualAssistantAdvantage';

const VirtualAssistantAbout = () => {
  const underlineStyle = {
    width: '0',
    height: '4px',
    backgroundColor: '#1a5252',
    position: 'absolute',
    bottom: '0',
    left: '0',
    animation: 'underline-animation 4s infinite',
  };

  const keyframesStyle = `
    @keyframes underline-animation {
      0% { width: 0; }
      50% { width: 100%; }
      100% { width: 0; }
    }
  `;

  return (
    <>
    <div className="pt-14 flex flex-col-reverse md:flex-row justify-around px-4 md:pt-24 md:px-10 bg-slate-100">
      {/* Embed CSS for animation */}

      <div className="w-full md:w-[49%]  text-start pt-2">
        <h1 className="text-[#1a5252] uppercase text-4xl md:text-4xl pb-1 font-bold relative inline-block">
        Virtual Assistance 
        </h1>
        <p className="py-3 text-gray-400 ">
        At Get to Peaks, we offer professional Virtual Assistance Services designed to help businesses operate more efficiently by taking care of time-consuming tasks. Whether you're a startup, small business, or growing enterprise, our virtual assistants provide the support you need to focus on what matters most—growing your business. We handle everything from administrative tasks to customer support, allowing you to streamline operations and save valuable time. Let our team help you stay organized, productive, and ahead of the competition, ensuring you have the freedom to innovate and expand your business horizons.
        </p>
      </div>

      <div className="w-full md:w-[49%] p-3 mb-6 md:mb-0">
        <div className="grid grid-cols-1 gap-4">
          <img
            className="rounded-2xl w-full sm:h-[300px]"
            src="https://img.freepik.com/free-photo/close-up-asian-woman-with-headphones-talking-phone-call-people-customer-service-consultant-using-headset-microphone-working-call-center-computer-support-chat_482257-35265.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
            alt="Top image"
          />
        </div>
      </div>
    </div>
    <VirtualAssistantAdvantage />
  </>
  );
};

export default VirtualAssistantAbout;
