import React, { useState } from "react";
import * as emailjs from "@emailjs/browser";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaPinterest,
} from "react-icons/fa";

const Contact = () => {
  const [Name, setname] = useState("");
  const [Email, setemail] = useState("");
  const [Message, setmsg] = useState("");
  const [thankYouMessage, setThankYouMessage] = useState(""); // State for thank you message

  function Sendmail(event) {
    event.preventDefault(); // Prevent page refresh
    const Data = {
      to_name: Name,
      to_email: Email,
      message: Message,
    };
    const Service_id = "service_tnh8vm7";
    const Template_id = "template_lnvi6mo";
    const user_id = "dEsnaXfZMSs28aFX1";

    emailjs.send(Service_id, Template_id, Data, user_id).then(
      function (response) {
        setThankYouMessage("Thank you! Your message has been sent."); // Set thank you message
        setname(""); // Clear input fields
        setemail("");
        setmsg("");
      },
      function (error) {
        console.log("Error sending message:", error);
      }
    );
  }

  return (
    <section className="px-6 py-12 md:px-24 md:py-24 bg-white"> {/* Updated to white background */}
      {/* Header */}
      <header className="text-center mb-12">
        <h1 className="text-4xl font-bold text-[#1a5252]">Contact Us</h1> {/* Updated text color */}
        <p className="text-lg text-gray-600 mt-2">We'd love to hear from you!</p>
      </header>
      <div className="flex flex-col md:flex-row md:space-x-12">
        {/* Contact Information */}
        <aside className="md:w-1/3 mb-8 md:mb-0">
          <h2 className="text-2xl font-bold text-[#1a5252] mb-4">Contact Information</h2> {/* Updated text color */}
          <div className="space-y-4 text-gray-600">
            <div className="flex items-center">
              <FaPhoneAlt className="text-2xl mr-3 text-[#1a5252]" />
              <span>+44 7999 568729</span>
            </div>
            <div className="flex items-center">
              <FaEnvelope className="text-2xl mr-3 text-[#1a5252]" />
              <span>info@gettopeaks.com</span>
            </div>
            <div className="flex items-center">
              <FaMapMarkerAlt className="text-2xl mr-3 text-[#1a5252]" />
              <span>128 City Road, London EC1V 2NX, UK</span>
            </div>
            {/* Social Media Links */}
            <div className="flex space-x-4 mt-6">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <FaFacebook className="text-2xl text-blue-600" />
              </a>
              <a
                href="https://www.instagram.com/get_to_peaks/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <FaInstagram className="text-2xl text-pink-600" />
              </a>
              <a
                href="https://www.pinterest.com/Get_To_peaks/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Pinterest"
              >
                <FaPinterest className="text-2xl text-red-800" />
              </a>
              <a
                href="https://www.linkedin.com/company/get-to-peaks/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <FaLinkedin className="text-2xl text-blue-700" />
              </a>
            </div>
          </div>
        </aside>
        {/* Contact Form */}
        <section className="md:w-2/3">
          <form onSubmit={Sendmail}> {/* Change to form */}
            <div className="">
              <div className="flex flex-wrap -m-2">
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label htmlFor="name" className="leading-7 text-sm text-[#1a5252]">Name</label> {/* Updated text color */}
                    <input
                      type="text"
                      id="name"
                      name="name"
                      required
                      autoComplete="off"
                      className="w-full bg-gray-100 rounded border border-[#1a5252] focus:border-[#1a5252] focus:bg-white focus:ring-2 focus:ring-[#1a5252] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      value={Name} // Controlled input
                      onChange={(e) => setname(e.target.value)}
                    />
                  </div>
                </div>
                <div className="p-2 w-1/2">
                  <div className="relative">
                    <label htmlFor="email" className="leading-7 text-sm text-[#1a5252]">Email</label> {/* Updated text color */}
                    <input
                      type="email"
                      id="email"
                      name="email"
                      required
                      autoComplete="off"
                      className="w-full bg-gray-100 rounded border border-[#1a5252] focus:border-[#1a5252] focus:bg-white focus:ring-2 focus:ring-[#1a5252] text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      value={Email} // Controlled input
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="p-2 w-full">
                  <div className="relative">
                    <label htmlFor="message" className="leading-7 text-sm text-[#1a5252]">Message</label> {/* Updated text color */}
                    <textarea
                      id="message"
                      name="message"
                      className="w-full bg-gray-100 rounded border border-[#1a5252] focus:border-[#1a5252] focus:bg-white focus:ring-2 focus:ring-[#1a5252] h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                      value={Message} // Controlled input
                      onChange={(e) => setmsg(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                <div className="p-2 w-full">
                  <button
                    type="submit" // Change to submit button
                    className="flex mx-auto text-white bg-[#1a5252] border-0 py-2 px-8 focus:outline-none hover:bg-[#123b3b] rounded text-lg"
                  >
                    Send Message
                  </button>
                </div>
              </div>
              {thankYouMessage && ( // Conditionally render the thank you message
                <p className="text-green-500 text-center mt-4">{thankYouMessage}</p>
              )}
            </div>
          </form>
        </section>
      </div>
    </section>
  );
};

export default Contact;
