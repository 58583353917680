import React from "react";

const Experience = () => {
  return (
    <div className="py-14 flex flex-col-reverse md:flex-row justify-around px-4 md:py-24 md:px-20 bg-slate-100">
      {/* Text Section */}
      <div className="flex flex-col justify-center items-center w-full md:w-[49%] text-start pt-2">
        <h1 className="text-start text-[#1a5252] uppercase text-4xl md:text-4xl pb-1 font-bold relative inline-block">
          The Experience
         
        </h1>
        <p className="py-6 text-gray-400">
          GetToPeaks has over 3 years in the digital marketing industry and has helped businesses large & small tap into their online potential. With a skilled team of specialists across various services, including SEO, content marketing, PPC campaigns, and social media management, we are more than marketers — we are strategists, artists, and critical thinkers committed to delivering real results. Our services also include App Development, Web Development, Video Editing, and more, tailored to meet diverse client needs.
        </p>
      </div>

      {/* Image Section */}
      <div className="w-full md:w-[49%] p-3 mb-6 md:mb-0">
        <img
          className="rounded-2xl w-full"
          src="https://img.freepik.com/free-photo/corporate-team-workers-it-company_1303-19458.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
          alt="Our Team"
        />
      </div>

      {/* Injecting keyframe animation */}
      <style jsx>{`
        .underline-animation {
          width: 0;
          height: 4px;
          background-color: #1a5252;
          position: absolute;
          bottom: 0;
          left: 0;
          animation: underline-grow 4s infinite;
        }

        @keyframes underline-grow {
          0% {
            width: 0;
          }
          50% {
            width: 100%;
          }
          100% {
            width: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default Experience;
