import React from "react";
import { FaCheck } from "react-icons/fa"; // Importing check icon

const GDAdvantage = () => {
  // Service list data
  const services = [
    {
      title: "Logo Design & Branding",
      description: "Your logo is the cornerstone of your brand identity. We create unique, memorable logos that represent your business values and make your brand instantly recognizable. From color schemes to typography, every element is thoughtfully designed to establish a strong and cohesive brand image.",
    },
    {
      title: "Marketing Materials Design",
      description: "We design stunning marketing materials that help you connect with your target audience. Whether you need brochures, business cards, flyers, or banners, we create designs that not only look great but also resonate with your brand’s message and objectives.",
    },
    {
      title: "Website and Digital Graphics",
      description: "From website banners to digital ads, our team specializes in creating high-quality digital graphics that enhance your online presence. We focus on user experience (UX) to ensure that every graphic element complements your website’s design, improving engagement and conversion rates.",
    },
    {
      title: "Social Media Graphics",
      description: "Social media is a vital platform for brand communication, and visually appealing content is key to capturing attention. We design social media graphics that are tailored for platforms like Instagram, Facebook, and LinkedIn, ensuring your posts are both visually appealing and share-worthy.",
    },
    {
      title: "Packaging Design",
      description: "For businesses in the retail or e-commerce space, we offer professional packaging design services. We craft packaging that not only protects your products but also makes a bold statement, helping your brand stand out on the shelves.",
    },
    {
      title: "Infographics and Presentations",
      description: "Simplify complex data and ideas with custom-designed infographics and presentations. Our team creates visually engaging and easy-to-understand infographics that communicate key information clearly, making them perfect for marketing, internal communication, or customer education.",
    },
    {
      title: "Print Design",
      description: "From posters and banners to magazines and catalogs, we bring your print materials to life with stunning design and attention to detail. Whether you’re promoting a new product or launching a campaign, our print designs leave a lasting impression.",
    },
  ];
  

  // Advantages data
  const advantages = [
    {
      title: "Experienced Designers: ",
      description:
        "Our team consists of skilled graphic designers with years of experience in crafting creative solutions across industries.",
    },
    {
      title: "Tailored Solutions: ",
      description:
        "We believe in creating designs that are tailored to your brand and business needs, ensuring that every piece of work reflects your identity.",
    },
    {
      title: "Creativity Meets Strategy: ",
      description:
        "While we prioritize aesthetics, we also make sure that our designs are functional, with a clear strategy to communicate your message effectively and drive results.",
    },
    {
      title: "High-Quality Deliverables: ",
      description:
        "We ensure that all design deliverables are of the highest quality, optimized for both digital and print mediums, so your brand looks polished no matter where it’s displayed.",
    },
    {
      title: "Competitive Pricing: ",
      description:
        "We offer competitive pricing for our design services, making professional-quality graphic design accessible to businesses of all sizes.",
    },
  ];
  
  return (
    <div className="bg-gray-100 pb-14 px-6 md:py-24 md:px-12 lg:px-16">
      {/* Header Section */}
      <div className="text-center mb-12">
        <div className="flex flex-col  w-[100%] lg:flex-row py-4 gap-5 ">
          <img
            className="w-full  lg:w-96  sm:rounded-tr-full  "
            src="https://img.freepik.com/free-photo/still-life-graphic-design-studio_23-2151320743.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
            alt="WordPress Website Design"
          />
          <div>
            <h1 className="text-[#1a5252] uppercase text-4xl md:text-4xl pb-1 font-bold relative inline-block">
              Why is Graphic Design Important?
            </h1>
            <p className="pt-6 md:px-12 text-gray-500">
              In a world where first impressions matter, compelling graphic
              design is essential. Whether it’s your website, social media,
              print materials, or product packaging, the right design can
              capture attention, communicate your message effectively, and leave
              a lasting impact on your audience. Good design tells your brand’s
              story in a way that words alone cannot.
            </p>
          </div>
        </div>
      </div>

      {/* Our video editing Services Section */}
      <div className="text-start mb-12">
        <h1 className="text-[#1a5252] uppercase text-3xl font-bold mb-4">
          Our Graphic Designing Services Include:
        </h1>

        {/* Service List */}
        {services.map((service, index) => (
          <div key={index} className="pt-10">
            <h2 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold pb-1">
              {index + 1}. {service.title}
            </h2>
            <p className="pt-3 text-gray-500 sm:pr-56">{service.description}</p>
          </div>
        ))}
      </div>

      {/* Why Choose Us Section */}
      <div className="">
        <h1 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold mb-4">
        Why Choose Get to Peaks for Graphic Designing?
        </h1>
        <div className="sm:pr-64">
          {advantages.map((advantage, index) => (
            <p key={index} className="text-lg text-start pt-2 text-gray-500">
              <span className="font-bold text-black">• {advantage.title}</span>{" "}
              {advantage.description}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GDAdvantage;
