import React from "react";
import WordpressAdvantage from "../WordpressDevelopment/WordpressAdvantage";

const WordpressDevelopmentAbout = () => {
  return (
    <>
      <section className="pt-14 flex flex-col-reverse md:flex-row justify-around px-4 md:pt-24 md:px-10 bg-slate-100">
        {/* Left Text Section */}
        <div className="w-full md:w-[49%] flex flex-col justify-center text-start pt-2">
          <h1 className="text-[#1a5252] uppercase text-4xl md:text-4xl pb-1 font-bold relative inline-block">
            WordPress Development
          </h1>
          <p className="py-6 text-gray-400">
            At Get to Peaks, we specialize in providing top-notch WordPress
            Development Services that are tailored to suit your business needs.
            WordPress powers over 40% of the world’s websites, making it one of
            the most popular and versatile platforms. Whether you need a blog,
            business website, or e-commerce store, we leverage the full
            potential of WordPress to create stunning, functional, and scalable
            websites that help you grow your online presence.
          </p>
        </div>

        {/* Right Image Section */}
        <div className="w-full md:w-[49%] p-3 mb-6 md:mb-0">
          <div className="grid grid-cols-1 gap-4">
            <img
              className="rounded-2xl w-full sm:h-[300px] object-cover"
              src="https://img.freepik.com/free-photo/website-design-content-layout-graphic_53876-21203.jpg?ga=GA1.2.994116845.1714564752&semt=ais_hybrid"
              alt="Website design concept"
            />
          </div>
        </div>
      </section>

      <WordpressAdvantage />
    </>
  );
};

export default WordpressDevelopmentAbout;
