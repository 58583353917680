import React from 'react';

const SEOBox = () => {
  return (
    <div className="w-full bg-[#1a5252] flex justify-center items-center flex-col pt-12 px-4 md:px-8 text-center">
      {/* Embed CSS for animation */}
      <style>
        {`
          @keyframes underline-animation {
            0% { width: 0; }
            50% { width: 100%; }
            100% { width: 0; }
          }
          .underline-animation {
            width: 0;
            height: 4px;
            background-color: #ffffff;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            animation: underline-animation 8s infinite;
          }
        `}
      </style>

      <h1
        id="our"
        className="text-white text-3xl md:text-4xl pb-4 font-bold relative inline-block"
      >
        We provide you the best service
        <spana
          className="block absolute bottom-0 left-1/2 mt-1 underline-animation"
        ></spana>
      </h1>
      <p className="text-white text-base md:text-lg md:px-40 lg:px-60 pt-2 pb-5">
        Digital marketing encompasses a broad range of online strategies and
        activities aimed at promoting and branding products, services, or
        businesses using digital channels. It leverages the power of the
        internet and electronic devices to reach and engage with a target
        audience.
      </p>
      <a
        href="https://wa.me/+447999568729"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <button className="bg-white text-[#1a5252] font-bold py-2 px-6 md:px-8 rounded-full">
          Contact Us
        </button> */}
      </a>
    </div>
  );
};

export default SEOBox;
