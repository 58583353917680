import React from "react";
import Experience from "../Components/AboutPageFile/Experience";
import Work from "../Components/AboutPageFile/Work";
import OurMission  from "../Components/AboutPageFile/OurMission";

const AboutPage = () => {
  return (
    <div
      className="text-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEibAaFyA7CLlQ66PlPkTtZPQh1N7adWS0JT-XHuRrVTJNp61Z3CR6HWkdWAOrB0N-5AV68582_uzq_khR_yrVjQTPrA6ZNnarPJREpdkX-CTM1aS7mqdnuzYawlcZPZ4tp9L2nf0IfcB3hRsl_JdKylfS6yYjSRAlCByKG5Vcv4AyIBzu382RCF6AZb33UE/w587-h330/APP%20(7).png")`,
        backgroundSize: "cover", // Ensures the image covers the entire container
        backgroundPosition: "center",
        backgroundAttachment: "fixed", // Keeps the image fixed while scrolling
        minHeight: "100vh", // Ensures the container is at least full viewport height
        backgroundRepeat: "no-repeat", // Prevents repeating the image
      }}
    >
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h1 className="text-5xl sm:text-6xl font-bold pt-28 text-white">About Us</h1>
        <p className=" text-lg sm:text-3xl pt-1 sm:px-56 text-white">
        At Get to Peaks, we are a team of passionate digital marketing professionals dedicated to helping businesses succeed online. 
        </p>
      </div>
      <Experience />
      <Work />
      <OurMission />
    </div>
  );
};

export default AboutPage;
