import React from "react";
import WebPricing from "./WebPricing";
import WebDevelopmentAbout from "./WebDevelopmentAbout";
import WebAdvantage from "./WebAdvantage";
import WebBox from "./WebBox";

const WebDevelopment = () => {
  return (
    <>
      {/* Header Section */}
      <div
        className="flex items-center justify-center text-center bg-cover bg-center bg-fixed min-h-screen"
        style={{
          backgroundImage: 'url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjUxNRTHEFCuRMLD0zz-kPabcg6mIRhCja4sAOVBNk42FQue0I3QxYafHrTN5RkaeybXKr1_OIpBUOyrbNtHqfdKc53mGphUi9-jdBvip7ED_mHiKHQyanxz74iNoU4yHKJxKfvYEcIIu6uD-3uaRDYOwJO0awL-0g89xu0wdUSw5y68Bzfvv_5Xsd6uuBS/w546-h307/APP%20(6).png")',
        }}
      >
        <div className="px-6 sm:px-56 text-white">
          <h1 className="text-4xl sm:text-6xl font-bold pt-28">Web Development</h1>
          <p className="text-base sm:text-xl pt-4 max-w-2xl mx-auto">
            We develop sleek, responsive websites that reflect your brand and convert visitors into customers. From simple landing pages to fully integrated e-commerce platforms, we deliver solutions tailored to your needs.
          </p>
        </div>
      </div>

      {/* Main Content Sections */}
      <WebPricing />
      <WebDevelopmentAbout />
      <WebAdvantage />
      <WebBox />
    </>
  );
};

export default WebDevelopment;
