import React from "react";
import { FaCheck } from "react-icons/fa"; // Importing check icon

const VirtualAssistantAdvantage = () => {
  // Service list data
  const services = [
    {
      title: "Administrative Support",
      description: "Our virtual assistants handle all of your essential administrative tasks, including:",
      bold: "• Email and Calendar Management:",
      description1: "Organizing and scheduling appointments, responding to emails, and keeping you on track with deadlines.",
      bold1: "• Data Entry:",
      description2: "Keeping your records and databases up to date with accurate and organized information.",
      bold2: "• Document Preparation:",
      description3: "Formatting and preparing presentations, reports, and other business documents.",
    },
    {
      title: "Customer Service Support",
      description: "Deliver exceptional customer service without the overhead of an in-house team. Our virtual assistants provide:",
      bold: "• Email and Live Chat Support:",
      description1: "Promptly responding to customer inquiries, resolving issues, and ensuring customer satisfaction.",
      bold1: "• Order Processing and Follow-Up:",
      description2: "Managing customer orders, tracking shipments, and following up to ensure a seamless experience.",
      bold2: "• Handling Complaints and Feedback:",
      description3: "Addressing customer concerns and gathering valuable feedback to help improve your services.",
    },
    {
      title: "Social Media Management",
      description: "Building and maintaining a strong social media presence is vital for business growth. Our virtual assistants help you manage:",
      bold: "• Content Scheduling:",
      description1: "Posting consistent, engaging content on your social media platforms to keep your audience engaged.",
      bold1: "• Community Management:",
      description2: "Interacting with your followers, responding to comments, and fostering relationships with potential customers.",
      bold2: "• Analytics Reporting:",
      description3: "Tracking the performance of your social media campaigns and providing insights for improvement.",
    },
    {
      title: "Research and Data Analysis",
      description: "Let our virtual assistants gather and analyze the information you need to make informed business decisions. We provide:",
      bold: "• Market Research:",
      description1: "Investigating competitors, industry trends, and new opportunities for business growth.",
      bold1: "• Data Compilation:",
      description2: "Organizing and summarizing research data into clear and actionable insights.",
      bold2: "• Reports and Summaries:",
      description3: "Delivering research findings in easy-to-understand formats that help drive strategic planning.",
    },
    {
      title: "E-Commerce Support",
      description: "Running an online store? Our virtual assistants can help with:",
      bold: "• Product Listing Management:",
      description1: "Adding and updating product descriptions, images, and pricing.",
      bold1: "• Inventory Management:",
      description2: "Keeping track of stock levels and coordinating with suppliers to avoid inventory shortages.",
      bold2: "• Customer Support for Online Sales:",
      description3: "Handling inquiries related to orders, returns, and general product questions.",
    },
    {
      title: "Project Management",
      description: "Stay on top of your projects with our virtual assistants providing:",
      bold: "• Task Coordination:",
      description1: "Assigning tasks, tracking progress, and ensuring project deadlines are met.",
      bold1: "• Communication Liaison:",
      description2: "Coordinating between team members and clients to maintain smooth project workflows.",
      bold2: "• Progress Reporting:",
      description3: "Keeping you informed with regular updates on project milestones and completions.",
    },
  ];
  // Advantages data
  const advantages = [
    {
      title: "1.	Google Analytics Certification:",
      description:
        "This certification proves your ability to collect and interpret data using Google Analytics, one of the leading tools in digital marketing. By understanding how to analyze website traffic and performance metrics, you can provide valuable insights that drive marketing strategies. At Get to Peaks, we influence data-driven decisions to improve our video editing and virtual assistance services. Having this certification can open doors to opportunities at companies that prioritize data analysis, helping you become an integral part of their marketing team.",
    },
    {
      title: "2.	Digital Marketing Certification from a Recognized Institution:",
      description:
        "Completing a certification program from a reputable institution shows potential employers that you have a solid foundation in digital marketing principles. This certification covers various aspects, including SEO, content marketing, email marketing, and more. At Get to Peaks, we prioritize hiring professionals who possess strong digital marketing skills, ensuring that we deliver top-notch services to our clients. Holding this certification can set you apart in a competitive job market and demonstrate your commitment to the field.",
    },
    {
      title: "3.	Social Media Marketing Certification:",
      description:
        "Given the significant role social media plays in digital marketing, this certification showcases your ability to create and execute effective social media campaigns. As Get to Peaks continues to expand its online presence, expertise in social media marketing becomes increasingly valuable. This certification can be particularly beneficial if you aspire to work as a social media manager or specialist, allowing you to develop strategies that engage audiences and drive brand awareness.",
    },
   
  ];
  

  return (
    <div className="bg-gray-100 pb-14 px-6 md:pb-24 md:px-12 lg:px-16">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className="text-[#1a5252] uppercase pt-6 text-3xl font-bold mb-4">
          How to manage a digital marketing VA
        </h1>
        <div className="flex flex-col lg:flex-row py-4 gap-5">
  <div className="flex-shrink-0 w-full lg:w-96 h-full">
    <img
      className="w-full h-full  rounded-tr-full "
      src="https://img.freepik.com/free-photo/woman-working-with-laptop-front-view_23-2149741150.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
      alt="WordPress Website Design"
    />
  </div>
  
  <p className="flex-grow text-start md:pr-20 text-gray-500 flex items-center">
    Once you have hired a digital marketing VA, it is important to manage them effectively. This includes setting clear expectations, providing regular feedback, and making sure they have the resources they need to be successful. Our company, Get to Peaks, provides VAs and guides on how you can boost their online business and how you can earn in dollars. You should also meet with your VA regularly to review their progress and discuss any challenges they are facing.
  </p>
</div>


      </div>

      {/* Our video editing Services Section */}
      <div className="text-start mb-12">
        <h1 className="text-[#1a5252] uppercase text-3xl font-bold mb-4">
          Our Video Editing Services Include:
        </h1>

        {/* Service List */}
        {services.map((service, index) => (
          <div key={index} className="pt-10">
            <h2 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold pb-1">
              {index + 1}. {service.title}
            </h2>
            <p className="pt-3 text-gray-500">{service.description}</p>
            <div className="sm:ml-0 ">
              <p className="pt-8 text-gray-500 sm:pr-56">
                <span className="font-bold text-black"> {service.bold}</span>{" "}
                {service.description1}
              </p>
              <p className="pt-8 text-gray-500">
                <span className="font-bold text-black"> {service.bold1}</span>{" "}
                {service.description2}
              </p>
              <p className="pt-8 text-gray-500">
                <span className="font-bold text-black"> {service.bold2}</span>{" "}
                {service.description3}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Why Choose Us Section */}
      <div>
        <h1 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold mb-4">
        Key Certifications for Your Digital Marketing Career at Get to Peaks
        </h1>
        <p className="text-start md:pr-20 text-gray-500">
        As a digital marketing associate, earning certain certifications can significantly enhance your career prospects. At Get to Peaks, we believe in the importance of continuous learning and skill development. Here are three essential certifications that can help you grow in the field of digital marketing:
          </p>
        <div className="">
          {advantages.map((advantage, index) => (
            <p key={index} className="text-lg text-start pt-2 text-gray-500">
              <span className="font-bold text-black">• {advantage.title}</span>{" "}
              {advantage.description}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VirtualAssistantAdvantage;
