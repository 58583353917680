import React from "react";

const WordpressAdvantage = () => {
  // Service list data
  const services = [
    {
      title: "Custom WordPress Website Design",
      description:
        "We build custom WordPress websites that reflect your brand’s personality and meet your specific requirements. Our design team creates bespoke WordPress themes from scratch, ensuring that your website is unique, visually appealing, and aligned with your business goals.",
    },
    {
      title: "WordPress Theme Customization",
      description:
        "If you already have a theme but need to make it your own, we offer WordPress theme customization services. We modify existing themes to suit your preferences, from adjusting layouts and colors to adding new features, ensuring your website is both functional and beautiful.",
    },
    {
      title: "E-Commerce WordPress Development (WooCommerce)",
      description:
        "For businesses looking to sell products or services online, we offer WooCommerce development services. WooCommerce is the leading e-commerce platform built on WordPress, and we customize it to create secure, scalable, and user-friendly online stores. From product pages to secure payment gateways, we ensure your e-commerce site is optimized for sales and customer experience.",
    },
    {
      title: "Plugin Development and Customization",
      description:
        "WordPress plugins add functionality to your website, and sometimes, an off-the-shelf plugin isn’t enough. We offer custom plugin development to extend your site’s functionality and create a seamless experience for your users. Whether it’s integrating third-party services or adding unique features, we build plugins that meet your exact needs.",
    },
    {
      title: "WordPress Maintenance and Support",
      description:
        "Your WordPress website needs ongoing care to perform at its best. We provide maintenance and support services that include regular updates, security patches, and performance optimization. We ensure your site stays secure, fast, and up-to-date, giving you peace of mind and allowing you to focus on your business.",
    },
    {
      title: "SEO-Optimized WordPress Development",
      description:
        "A well-optimized website is crucial for attracting organic traffic. We develop SEO-friendly WordPress websites that rank higher on search engines. From clean coding to optimized loading times and on-page SEO best practices, we ensure your website is ready to attract and convert visitors.",
    },
    {
      title: "Responsive WordPress Design",
      description:
        "With more people browsing the web on mobile devices, having a fully responsive website is a must. We build responsive WordPress websites that adapt seamlessly to different screen sizes, ensuring a consistent user experience on desktops, tablets, and smartphones.",
    },
    {
      title: "WordPress Migration",
      description:
        "Looking to move your website to WordPress? We offer smooth and secure WordPress migration services, ensuring all your content, data, and SEO rankings are safely transferred without any downtime or data loss.",
    },
  ];

  // Advantages data
  const advantages = [
    {
      title: "Experienced WordPress Developers:",
      description:
        "Our team has years of experience in creating high-performing WordPress websites for businesses across various industries.",
    },
    {
      title: "Custom Solutions:",
      description:
        "We don’t believe in one-size-fits-all. Every website we develop is customized to fit the specific needs of your business.",
    },
    {
      title: "User-Friendly CMS:",
      description:
        "With WordPress, you’ll have full control over your website’s content. We create easy-to-manage websites, so you can update and manage your content effortlessly.",
    },
    {
      title: "Scalable and Flexible:",
      description:
        "Whether you’re starting small or running a large enterprise, our WordPress solutions are designed to scale as your business grows.",
    },
    {
      title: "Affordable and Cost-Effective:",
      description:
        "We offer affordable WordPress development services that deliver high-quality results without breaking your budget.",
    },
  ];

  // Get to Peaks advantages data
  const getToPeaksAdvantages = [
    {
      title: "Collaborative Process:",
      description:
        "We work closely with you from concept to completion, ensuring your vision comes to life in the final product.",
    },
    {
      title: "On-Time Delivery:",
      description:
        "We understand the importance of timelines and deliver projects on schedule without sacrificing quality.",
    },
    {
      title: "Ongoing Support:",
      description:
        "Our partnership doesn’t end with the launch of your website. We offer ongoing support and maintenance to ensure your WordPress site continues to perform optimally.",
    },
    {
      title: "Performance and Security:",
      description:
        "We prioritize speed, security, and performance in every WordPress site we develop, ensuring a fast and secure user experience.",
    },
  ];
  return (
    <div className="bg-gray-100 pb-14 px-6 md:pb-24 md:px-12 lg:px-16">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className="text-[#1a5252] uppercase pt-6 text-3xl font-bold mb-4">
          Why We Choose WordPress for Our Business Website?
        </h1>
        <div className="flex flex-col lg:flex-row py-4 gap-5">
          <img
            className="w-full lg:w-1/3 sm:rounded-tr-full"
            src="https://img.freepik.com/premium-photo/website-design-software-provide-snugly-template-online-retail-business_31965-617562.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
            alt="WordPress Website Design"
          />
          <p className="text-start text-gray-400">
            Choosing WordPress for your business website is smart due to its
            user-friendly interface, making it easy to manage without technical
            expertise. It's highly customizable with thousands of themes and
            plugins, allowing you to tailor your site to your needs. WordPress
            is also SEO-friendly, helping improve search engine rankings, and
            offers mobile-responsive designs for optimal user experience.
            Additionally, it’s a cost-effective solution compared to
            custom-built websites, making it perfect for businesses of all
            sizes. WordPress also benefits from a large community, offering
            abundant resources for troubleshooting and learning. Frequent
            updates ensure that your website stays secure and compatible with
            the latest technologies.
          </p>
        </div>
      </div>

      {/* Our WordPress Services Section */}
      <div className="text-start mb-12">
        <h1 className="text-[#1a5252] uppercase text-3xl font-bold mb-4">
          Our WordPress Services Include:
        </h1>

        {/* Service List */}
        {services.map((service, index) => (
          <div key={index} className="pt-10">
            <h2 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold pb-1">
              {index + 1}. {service.title}
            </h2>
            <p className="py-3 text-gray-400">{service.description}</p>
          </div>
        ))}
      </div>

      {/* Why Choose Us Section */}
      <div>
        <h1 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold mb-4">
          Why Choose Get to Peaks for WordPress Services?
        </h1>
        <div className="sm:pr-64">
          {advantages.map((advantage, index) => (
            <p key={index} className="text-lg text-start pt-2 text-gray-500">
              <span className="font-bold text-black">• {advantage.title}</span>{" "}
              {advantage.description}
            </p>
          ))}
        </div>

        {/* The Get to Peaks Advantage Section */}
        <h1 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold mb-4 pt-10">
          The Get to Peaks Advantage
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 text-center gap-4">
          {getToPeaksAdvantages.map((advantage, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-4">
              <p className="font-bold text-black text-xl">{advantage.title}</p>
              <p className="text-base text-gray-500">{advantage.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WordpressAdvantage;
