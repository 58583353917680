import React from "react";
import VideoEditingAbout from "./VideoEditingAbout";
import VideoEditingBox from "./VideoEditingBox";
import VideoEditingAdvantage from "./VideoEditingAdvantage";
import VideoEditingPricing from "./VideoEditingPricing";



const SMM = () => {
  return (
    <>
      <div
        className="text-center bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhP2jVIlokMBS5Z47NZr1kthwVq7YGrKdx2xp35aW22K3SSn7b_qKuwk_KsWZXQ5Bj_5W_qVlJHAXt62Vrkr_v1tR_t816jZeYrs1RqdKMmbII8tIOahFLnuOQv2q4mUtSLgdhhv1Sg3R8kkcfS0o8P0Sy_ofcm8XyVS0PyICk2y_-5lZYDMmcyrpFwDal1/w1684-h1069-p-k-no-nu/APP.png")`,
          backgroundSize: "cover", // Ensures the image covers the entire container
          backgroundPosition: "center",
          backgroundAttachment: "fixed", // Keeps the image fixed while scrolling
          minHeight: "100vh", // Ensures the container is at least full viewport height
          backgroundRepeat: "no-repeat", // Prevents repeating the image
        }}
      >
        <div className="flex flex-col items-center justify-center min-h-screen">
          <h1 className="text-5xl sm:text-6xl font-bold pt-28 text-white">Video Editing</h1>
          <p className="sm:text-xl pt-1 px-6 sm:px-56 text-white">
          We turn raw footage into polished, captivating videos that showcase your brand’s message, using creative techniques to engage your audience effectively.
          </p>
        </div>
      </div>
    <VideoEditingPricing />
    <VideoEditingAbout />
    <VideoEditingBox />
    {/* <VideoEditingAdvantage /> */}
    </>
  );
};

export default SMM;
