import React from "react";

const Experience = () => {
  return (
    <div className="py-14 md:py-24 flex flex-col-reverse md:flex-row justify-around px-4 md:px-20 bg-slate-100">
      {/* CSS for underline animation */}
      <style jsx>{`
        .underline-animation {
          width: 0;
          height: 4px;
          background-color: #1a5252;
          position: absolute;
          bottom: 0;
          left: 0;
          animation: underline-grow 4s infinite;
        }

        @keyframes underline-grow {
          0% { width: 0; }
          50% { width: 100%; }
          100% { width: 0; }
        }
      `}</style>

      {/* Image Section */}
      <div className="w-full md:w-[49%] p-3 mb-6 md:mb-0">
        <img
          className="rounded-2xl w-full"
          src="https://img.freepik.com/free-photo/african-european-girls-looking-computer-screen-while-blonde-lady-reading-documents-indoor-portrait-asian-student-glasses-talking-with-female-friends-uniersity_197531-3792.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
          alt="Team collaborating"
        />
      </div>

      {/* Text Content Section */}
      <div className="flex flex-col justify-center w-full md:w-[49%] text-start pt-2">
        <h1 className="text-[#1a5252] uppercase text-4xl font-bold relative inline-block">
          Our Mission
        </h1>
        <p className="py-3 text-gray-400 leading-relaxed">
          Our mission is to empower businesses to reach their full potential by
          offering innovative, tailored digital solutions. We are dedicated to
          understanding each client’s unique needs, whether it’s building a
          modern, responsive website, creating a high-performing mobile app, or
          executing a robust SEO strategy. Through our expertise and commitment
          to excellence, we deliver measurable results that help businesses grow
          and excel in a competitive digital landscape. We believe in forging
          long-lasting partnerships with our clients, providing continuous
          support and growth.
        </p>
      </div>
    </div>
  );
};

export default Experience;
