import React from "react";
import ShopifyStoreAbout from "./ShopifyStoreAbout";
import ShopifyStoreBox from "./ShopifyStoreBox";
import ShopifyStorePricing from "./ShopifyStorePricing";
// import ShopifyStoreAdvantage from "./ShopifyStoreAdvantage";



const ShopifyStore = () => {
  return (
    <>
      <div
        className="text-center bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjEvt8L_fAPCz_Q7qYTENTZ_KKYzjanaSPSWfhg-vSDHoZJWUYk5BEpsI15Tg9HKoI3otWIij9vMRwpdSnVrSZIF5E9MDJScxn4R1RskgisrcyIhVYFNckEmY6ptnnGw06P1i0FqLoL_gWqMEvjsLU_pGZYFouuTfRtHVcoLEblsk0GkZa7SuUEA518sJEN/w1684-h1069-p-k-no-nu/APP%20(2).png")`,
          backgroundSize: "cover", // Ensures the image covers the entire container
          backgroundPosition: "center",
          backgroundAttachment: "fixed", // Keeps the image fixed while scrolling
          minHeight: "100vh", // Ensures the container is at least full viewport height
          backgroundRepeat: "no-repeat", // Prevents repeating the image
        }}
      >
        <div className="flex flex-col items-center justify-center min-h-screen">
          <h1 className="text-5xl sm:text-6xl font-bold pt-28 text-white">Shopify Store</h1>
          <p className="sm:text-xl pt-1 px-6 sm:px-56 text-white">
          At Get to Peaks, we offer expert Shopify Development services that help businesses create powerful e-commerce stores. Whether you are launching a new online store or looking to enhance your Shopify website, our team is here to provide tailored solutions that drive sales and customer engagement.
          </p>
        </div>
      </div>
    <ShopifyStorePricing />
    <ShopifyStoreAbout />
    <ShopifyStoreBox />
    {/* <ShopifyStoreAdvantage /> */}
    </>
  );
};

export default ShopifyStore;
