import React from "react";
import AppDevelopmentAbout from "./AppDevelopmentAbout";
import AppDevelopmentBox from "./AppDevelopmentBox";
import AppAdvantage from "./AppAdvantage";
import AppPricing from "./AppPricing";

const AppDevelopment = () => {
  const backgroundImageUrl =
    "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgSy_G_yYOCYCm0sKeebh6epmiX7JU3aTnnLHAKgG7UmGZc6CBtUH_gqbf1r9_Vu81KLIOwjvS25uPP5U6u4aScy5STCaEqzlLfkJSbTHCz7AMnh0F4ysmhJPOknErvb5i5zWUHE4GxvH3jYeo-Qn9WspCXKMXHFCBuzeKt1o09JJ7-kbOpg7F3bexZFiWi/w1684-h1069-p-k-no-nu/pic.png";

  return (
    <>
      <div
        className="text-center bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          minHeight: "100vh",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex flex-col items-center justify-center min-h-screen">
          <h1 className="text-4xl sm:text-6xl font-bold pt-28 text-white">
            App Development
          </h1>
          <p className="text-base sm:text-xl pt-1 px-6 sm:px-56 text-white">
            Our team specializes in crafting mobile and web apps that are not
            only functional but also offer a seamless user experience. We ensure
            that your app stands out in today's competitive digital landscape.
          </p>
        </div>
      </div>
      <AppPricing />
      <AppDevelopmentAbout />
      <AppDevelopmentBox />
      {/* Uncomment the line below to include the AppAdvantage component */}
      {/* <AppAdvantage /> */}
    </>
  );
};

export default AppDevelopment;
