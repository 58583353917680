import React from "react";
import VideoEditingAdvantage from "./VideoEditingAdvantage";

const VideoEditingAbout = () => {
  return (
    <>
      <div className="pt-14 flex flex-col-reverse md:flex-row justify-around px-4 md:pt-24 md:px-10 bg-slate-100">
        {/* Embed CSS for animation */}

        <div className="w-full md:w-[49%]  text-start pt-2">
          <h1 className="text-[#1a5252] uppercase text-4xl md:text-4xl pb-1 font-bold relative inline-block">
          Video Editing
          </h1>
          <p className="py-3 text-gray-400 ">
          At Get to Peaks, we offer expert Video Editing Services that transform your raw footage into polished, engaging videos that captivate your audience. Whether you need a promotional video for your business, dynamic social media content, or high-quality corporate videos, our team of skilled editors will help bring your creative vision to life. With meticulous attention to detail, we enhance visuals, add impactful transitions, and integrate motion graphics, ensuring your videos stand out. Let us elevate your brand with compelling storytelling, designed to leave a lasting impression on your target audience.
          </p>
        </div>

        <div className="w-full md:w-[49%] p-3 mb-6 md:mb-0">
          <div className="grid grid-cols-1 gap-4">
            <img
              className="rounded-2xl w-full sm:h-[300px]"
              src="https://img.freepik.com/free-photo/creative-filmmaker-working-post-production-movie-while-working-from-home-girlfriend-background-is-walking-house-talking-phone_482257-22375.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
              alt="Top image"
            />
          </div>
        </div>
      </div>
      <VideoEditingAdvantage />
    </>
  );
};

export default VideoEditingAbout;
