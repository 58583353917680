import React from "react";
import ShopifyStoreAdvantage from "./ShopifyStoreAdvantage";

const ShopifyStoreAbout = () => {
  return (
    <>
      <div className="pt-14 flex flex-col-reverse md:flex-row justify-around px-4 md:pt-24 md:px-10 bg-slate-100">
        {/* Embed CSS for animation */}

        <div className="w-full md:w-[49%] flex  flex-col justify-center text-start pt-2">
          <h1 className="text-[#1a5252] uppercase text-4xl md:text-4xl pb-1 font-bold relative inline-block">
            Shopify Store
          </h1>
          <p className="py-6 text-gray-400 ">
          At Get to Peaks, we offer expert Shopify Store Services designed to help businesses create robust, scalable, and user-friendly e-commerce platforms. Whether you’re launching a new online store or optimizing an existing one, our team of Shopify specialists will ensure your store is equipped to succeed in today’s competitive online marketplace. From seamless integrations to custom designs and advanced features, we tailor each store to meet the specific needs of your business. Our goal is to empower your brand with an efficient, high-converting online presence that drives sales and growth.
          </p>
        </div>

        <div className="w-full md:w-[49%] p-3 mb-6 md:mb-0">
          <div className="grid grid-cols-1 gap-4">
            <img
              className="rounded-2xl w-full sm:h-[300px]"
              src="https://img.freepik.com/free-photo/cyber-monday-shopping-sales_23-2148688502.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
              alt="Top image"
            />
          </div>
        </div>
      </div>
      <ShopifyStoreAdvantage />
    </>
  );
};

export default ShopifyStoreAbout;
