import React from "react";
import Contact from "../Components/ContactPageFile/Contact";

const ContactPage = () => {
  return (
    <>
    <div
      className="text-center bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhjP4CFO48WzgT-Bhyphenhyphent3JcDVJ1sZCnhzh7W-6GlgAG6ZyeL2Q7feUyBOI7GMo3tBDto-ShrWujhx0TMx0uKkL0VnJK9LRkeQuZm6kyoEStACecxz_eK3OlX-_KfqbnvNw6I5BfSveBakD9LMbFJVych5bn9SREicyyOt1Ujel9gzGBr-LFkJ_PR_7mpwzho/w616-h347/APP%20(8).png")`,
        backgroundSize: "cover", // Ensures the image covers the entire container
        backgroundPosition: "center",
        backgroundAttachment: "fixed", // Keeps the image fixed while scrolling
        minHeight: "100vh", // Ensures the container is at least full viewport height
        backgroundRepeat: "no-repeat", // Prevents repeating the image
      }}
    >
      <div className="flex flex-col items-center justify-center min-h-screen">
        <h1 className="text-6xl font-bold pt-28 text-white">Conact Us</h1>
        <p className="text-lg sm:text-3xl pt-1 sm:px-56 text-white">
        Our team is dedicated to assisting you. Your feedback matters!"
        </p>
      </div>
      
    </div>
    <Contact />
      </>
  );
};

export default ContactPage;
