import React from "react";
import { Link } from "react-router-dom";

const OurServices = () => {
  const services = [
    { title: "Web Development", description: "Crafting user-friendly, responsive websites tailored to your needs." , link:"/webdevelopment" },
    { title: "App Development", description: "Building powerful, secure apps to engage your audience." , link:"/appdevelopment" },
    { title: "Wordpress", description: "Unique, custom-built websites designed just for you." , link:"/wordpressdevelopment" },
    { title: "SEO", description: "Optimizing your site for higher search rankings and better visibility." , link:"/seo" },
    { title: "Graphic Design", description: "Stunning visuals that capture your brand's essence."  , link:"/graphicdesign"},
    { title: "Video Editing", description: "Producing high-quality video content for your marketing needs." , link:"/videoediting" },
    { title: "Shopify Store", description: "Building and optimizing your Shopify store for better sales." , link:"/shopifystore" },
    { title: "Virtual Assistant", description: "Providing administrative support to streamline your business operations."  , link:"/virtualassistant" },
  ];

  return (
    <section className="py-14 px-10 md:py-24 md:px-24 text-center">
      <header>
        <h1 className="text-[#1a5252] uppercase text-4xl font-extrabold">
          Our Services
        </h1>
        <p className="pt-4 text-gray-400">
          From website design to social media management, we offer a wide range of services to help you grow your business.
        </p>
      </header>

      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {services.map((service, index) => (
          <div
            key={index}
            className="relative group cursor-pointer p-4 bg-[#408282] hover:bg-[#1a5252] hover:shadow-2xl rounded-lg transition-transform duration-500 ease-in-out transform hover:scale-105"
          >
            {/* Title shown by default, hidden on hover */}
            <div className="absolute inset-0 flex items-center justify-center group-hover:hidden">
              <h2 className="text-2xl font-bold text-white">{service.title}</h2>
            </div>

            {/* Full content displayed on hover */}
            <div className="relative opacity-0 group-hover:opacity-100 transition-opacity duration-500">
              <h2 className="text-xl font-bold mb-1 text-white">{service.title}</h2>
              <p className="mb-2 text-white">{service.description}</p>
              <Link
                to={service.link}
                className="inline-block bg-white text-black text-sm font-semibold rounded-full px-4 py-2 mt-2"
              >
                Learn More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default OurServices;
