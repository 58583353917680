import React from "react";
import SEOAbout from "./SEOAbout";
import SEOBox from "./SEOBox"
import SEOAdvantage from "../AppDevelopment/AppAdvantage";
import SEOPricing from "./SEOPricing";


const SEO = () => {
  return (
    <>
      <div
        className="text-center bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiE9NHgyc1PQAmWybA84ObmFGTdSXyesnODn_2KDiCH9ugSGPFzYZaPh9QD4lqVdOhSTV26vWHLUOCSj_hlNztmuHkQrWs97taKL93Cksk04eV0R1_jOeN2kU6IqmprVfqynA_AE-oQd9nZURRwn-Ydbpix220jgDny7FHb0Rz9Kil1lsFdHkJdB0LfrZyf/w510-h287/APP%20(3).png")`,
          backgroundSize: "cover", // Ensures the image covers the entire container
          backgroundPosition: "center",
          backgroundAttachment: "fixed", // Keeps the image fixed while scrolling
          minHeight: "100vh", // Ensures the container is at least full viewport height
          backgroundRepeat: "no-repeat", // Prevents repeating the image
        }}
      >
        <div className="flex flex-col items-center justify-center min-h-screen">
          <h1 className="text-6xl font-bold pt-28 text-white">SEO</h1>
          <p className="sm:text-xl pt-1 px-6 sm:px-56 text-white">
          Drive more traffic to your website with our expert Search Engine Optimization services. We will help you rank higher on search engines, increase visibility, and improve user engagement.
          </p>
        </div>
      </div>
    <SEOPricing />
    <SEOAbout />
    <SEOBox />
    {/* <SEOAdvantage /> */}
    </>
  );
};

export default SEO;
