import React from "react";
import bg from "../../src/bg.mp4";
import bgmobile1 from "../../src/bgmobile1.mp4";

const HeroSection = () => {
  return (
    <section
      className="relative text-center h-screen overflow-hidden"
      style={{
        backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhhTu34Ucd6A0VJvJuzK7ICiTf27xl3DnO4pRJlN4BZzkh4Z9s6EkWOuUMqzXrZ2BOdIF_PkRZ1aA6BWd_OTfDtFgdmysKu9WEKqwIV4fOfmh0lbNDphROjeeo4fm41QQuZJjyJ-HqT6Fc0rzA1Y9fX6FuwDYzGiyX90nDpYRdm-E1xgA4PTYaMJCV6xEaI/w572-h322/APP%20(10).png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        minHeight: "100vh",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Video Background */}
      {/* Mobile Video */}
      {/* <video className="sm:hidden absolute inset-0 w-full h-full object-cover" autoPlay loop muted preload="auto">
        <source src={bgmobile1} type="video/mp4" />
      </video> */}
      {/* Desktop Video */}
      {/* <video className="hidden sm:block absolute inset-0 w-full h-full object-cover" autoPlay loop muted preload="auto">
        <source src={bg} type="video/mp4" />
      </video> */}

      {/* Hero Content */}
      <div className="relative pt-20 flex flex-col items-center justify-center h-full text-white bg-opacity-50">
        <h1 className="text-4xl md:text-6xl font-bold">Digital Marketing Agency</h1>
        <p className="text-lg md:text-3xl pt-3 font-bold">
          Climb Higher with Get To Peaks - Your Business Solution
        </p>
        <p className="text-base pt-2 px-6 sm:px-[10%] md:px-[20%] font-normal">
          A digital marketing agency specializing in professional services to enhance business online visibility, reach, and revenue through websites, social media, emails, and content marketing.
        </p>
      </div>
    </section>
  );
};

export default HeroSection;
