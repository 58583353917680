import React from 'react'
import HeroSection from "./Components/HeroSection";
import OurServices from "./Components/OurServices";
import About from "./Components/About";
import Cards from "./Components/Cards";
import Reached from "./Components/Reached";
import Business from "./Components/Business";
import BusinessNeed from "./Components/BusinessNeed";
import BlogCards from './Components/BlogCards';
import  ChooseUs  from './Components/ChooseUs';
import FAQ from './Components/FAQ';

const Home = () => {
  return (
    <div>
        <HeroSection />
        {/* <Cards /> */}
        <OurServices />
        <About />
        <Reached />
        <Business />
        <ChooseUs />
        {/* <BlogCards /> */}
        <BusinessNeed />
        <FAQ />
    </div>
  )
}

export default Home