import React from "react";
import { Link } from "react-router-dom";
import {
  FaCode,
  FaMobileAlt,
  FaWordpress,
  FaSearch,
  FaPencilAlt,
  FaFacebookF,
  FaShoppingCart,
  FaRobot,
  FaVideo,
} from "react-icons/fa"; // Importing icons

const ServicesCards = () => {
  const services = [
    { title: 'Web Development', description: 'Building and enhancing websites for optimal user experience, blending design and functionality seamlessly.', link: '/webdevelopment', icon: <FaCode size={40} className="text-[#1a5252]" /> },
    { title: 'App Development', description: 'We build custom mobile apps for iOS and Android. Our apps are user-friendly, high-performing, and tailored to your needs.', link: '/appdevelopment', icon: <FaMobileAlt size={40} className="text-[#1a5252]" /> },
    { title: 'WordPress Development', description: 'Get a custom website with our WordPress services! We design, build, and maintain secure, fast, and user-friendly sites with top-notch support.', link: '/wordpressdevelopment', icon: <FaWordpress size={40} className="text-[#1a5252]" /> },
    { title: 'SEO', description: 'Elevate your online visibility with strategic optimization techniques. Ensure your website ranks higher and attracts the right audience.', link: '/seo', icon: <FaSearch size={40} className="text-[#1a5252]" /> },
    { title: 'Graphic Design', description: 'Transforming ideas into visually compelling images that captivate and communicate your brand’s essence with creativity and precision.', link: '/graphicdesign', icon: <FaPencilAlt size={40} className="text-[#1a5252]" /> },
    { title: 'Social Media Management', description: 'We offer social media management and ad creation services. We can help you grow your social media presence and reach new customers.', link: '/socialmedia', icon: <FaFacebookF size={40} className="text-[#1a5252]" /> },
    { title: 'Shopify Store', description: 'Your one-stop e-commerce solution for creating, managing, and growing your online business effortlessly. Simplify your journey to success.', link: '/shopifystore', icon: <FaShoppingCart size={40} className="text-[#1a5252]" /> },
    { title: 'Virtual Assistant', description: 'AI-powered software aiding with scheduling, reminders, and information retrieval. Boost your efficiency and productivity.', link: '/virtualassistant', icon: <FaRobot size={40} className="text-[#1a5252]" /> },
    { title: 'Video Editing', description: 'Seamlessly edit and enhance your videos with professional tools and techniques. Create captivating content with ease.', link: '/videoediting', icon: <FaVideo size={40} className="text-[#1a5252]" /> },
  ];

  return (
    <div className="py-16 px-10 md:py-24 md:px-28">
      <div className="text-center">
        <h1 className="text-start text-[#1a5252] uppercase text-5xl md:text-4xl pb-1 font-bold relative inline-block">
          Our Services
          <span className="block w-full h-1 bg-[#1a5252] mt-2 mx-auto animate-underline"></span>
        </h1>
        <p className="pt-4 text-lg text-gray-500 leading-relaxed max-w-xl mx-auto">
          From website design to social media management, we offer a wide range of services to help you grow your business.
        </p>
      </div>

      <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <div
            key={index}
            className="relative group rounded-xl bg-white shadow-lg hover:shadow-2xl text-center transition-transform transform hover:-translate-y-2 duration-500"
          >
            <div className="p-8 relative z-10">
              <div className="mb-5 flex justify-center">{service.icon}</div>
              <h2 className="text-2xl font-semibold text-gray-800 mb-3">{service.title}</h2>
              <p className="text-gray-600 leading-relaxed mb-4">{service.description}</p>
              <Link
                to={service.link}
                className="inline-block border-2 border-transparent rounded-full text-sm font-semibold text-[#1a5252] px-6 py-2 transition-all duration-300 transform hover:bg-[#1a5252] hover:text-white hover:scale-105"
              >
                Learn More
              </Link>
            </div>
            <div className="absolute inset-0 bg-[#1a5252] bg-opacity-10 group-hover:bg-opacity-20 rounded-xl transition-all duration-500"></div>
          </div>
        ))}
      </div>

      <style jsx>{`
        @keyframes underline {
          0% { width: 0; }
          50% { width: 100%; }
          100% { width: 0; }
        }
      `}</style>
    </div>
  );
};

export default ServicesCards;
