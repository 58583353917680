import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa'; // Importing tick and cross icons

const PricingPlan = ({ plan }) => (
  <div
    className={`${plan.bgColor} ${plan.textColor} shadow-lg border-2 border-[#1a5252] rounded-3xl p-4 flex flex-col transition-transform duration-300 scale-95`}
  >
    <h3 className="text-2xl font-bold mb-1">{plan.title}</h3>
    <div className="flex-grow">
      <p className="text-2xl font-bold mb-1">{plan.price}</p>
      <p className="text-sm text-gray-500 mb-3">{plan.subtitle}</p>
      <ul className="text-sm flex-grow p-0 m-0">
        {plan.features.map((feature, idx) => (
          <li key={idx} className="flex items-center mb-2">
            {feature.icon}
            <span className="ml-2">{feature.text}</span>
          </li>
        ))}
      </ul>
    </div>
    <a
      href={plan.buttonLink}
      target="_blank"
      rel="noopener noreferrer"
      className="bg-[#1f6969] text-white font-bold py-2 rounded-full mt-4 text-center transition duration-300 hover:bg-[#1a5252]"
    >
      {plan.buttonText}
    </a>
  </div>
);

const WebPricing = () => {
  const plans = [
    {
      title: 'Basic',
      price: '$150',
      subtitle: 'Single Static Page + Upto 3 Sections + Responsive Design + Pixel Perfect UI + Smooth Performance',
      features: [
        { text: '1 page', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Responsive design', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Source code', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Content upload', icon: <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Design customization', icon: <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Detailed code comments', icon: <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" /> },
      ],
      buttonText: 'Get Started',
      buttonLink: '/basic-plan',
      bgColor: 'bg-white',
      textColor: 'text-gray-800',
    },
    {
      title: 'Standard',
      price: '$250',
      subtitle: 'Upto 3 Pages with separate routes + Provided API integration + Responsive + Smooth',
      features: [
        { text: '1 page', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Responsive design', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Source code', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Content upload', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Design customization', icon: <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Detailed code comments', icon: <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" /> },
      ],
      buttonText: 'Get Started',
      buttonLink: '/standard-plan',
      bgColor: 'bg-gray-100',
      textColor: 'text-gray-800',
    },
    {
      title: 'Premium',
      price: '$400',
      subtitle: 'Best for small businesses and startups that need a performant website that looks great and converts visitors to customers.',
      features: [
        { text: '1 page', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Responsive design', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Source code', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Content upload', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Design customization', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
        { text: 'Detailed code comments', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> },
      ],
      buttonText: 'Get Started',
      buttonLink: '/premium-plan',
      bgColor: 'bg-white',
      textColor: 'text-gray-800',
    },
  ];

  return (
    <div className="py-8">
      <h2 className="text-4xl font-bold text-center mb-8">Pricing Plans</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-10">
        {plans.map((plan, index) => (
          <PricingPlan key={index} plan={plan} />
        ))}
      </div>
    </div>
  );
};

export default WebPricing;
