import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa"; // Importing icons

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is digital marketing?",
      answer:
        "Digital marketing uses the internet and digital tools to promote products or services. It includes things like SEO, social media, and email marketing. The goal is to reach and engage with customers online.",
    },
    {
      question: "Why is digital marketing important?",
      answer:
        "Digital marketing helps businesses reach more people, target specific groups, and measure results easily. It is cost-effective and can help increase traffic, leads, and sales.",
    },
    {
      question: "What is Search Engine Optimization?",
      answer:
        "Search Engine Optimization (SEO) improves your website for search engines so more people can find it. It involves using keywords, creating good content, and getting links from other sites.",
    },
    {
      question: "What are the advantages of WordPress?",
      answer:
        "The advantages of WordPress include ease of use, extensive customization options with themes and plugins, and strong SEO capabilities. It has a large community for support and is scalable for any size website.",
    },
  ];

  return (
    <div className="my-14 md:my-24 mx-auto max-w-5xl p-6 bg-[#1a5252] text-white rounded-lg">
      <h2 className="text-center text-3xl mb-10">Frequently Asked Questions</h2>
      {faqs.map((faq, index) => (
        <FAQItem 
          key={index} 
          faq={faq} 
          isActive={activeIndex === index} 
          toggleFAQ={() => toggleFAQ(index)} 
        />
      ))}
    </div>
  );
};

const FAQItem = ({ faq, isActive, toggleFAQ }) => (
  <div className="mb-6 border border-white rounded-lg overflow-hidden">
    <div
      className={`flex justify-between items-center p-4 bg-[#1a5252] cursor-pointer hover:bg-[#164d4d] transition-colors duration-300 ${
        isActive ? "border-b border-white" : ""
      }`}
      onClick={toggleFAQ}
    >
      <h3 className="text-lg font-semibold">{faq.question}</h3>
      {isActive ? <FaMinus className="text-white" /> : <FaPlus className="text-white" />}
    </div>
    <div
      className={`${
        isActive ? "max-h-screen p-4" : "max-h-0"
      } overflow-hidden transition-all duration-500 ease-in-out`}
    >
      <p className="text-base">{faq.answer}</p>
    </div>
  </div>
);

export default FAQ;
