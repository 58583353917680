import React from "react";
import { FaCheck } from "react-icons/fa"; // Importing check icon

const AppAdvantage = () => {
  return (
    <div className="bg-gray-100 pb-14 px-6 md:py-24 md:px-12 lg:px-16">
      {/* Header Section */}
      <div className="text-center mb-12">
        <div className="flex flex-col lg:flex-row py-4 gap-5">
          <img
            className="w-full lg:w-96 sm:rounded-tr-full"
            src="https://img.freepik.com/free-photo/representation-user-experience-interface-design_23-2150169853.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
            alt="App Development Services"
          />
          <div>
            <h1 className="text-[#1a5252] uppercase text-4xl pb-1 font-bold relative inline-block">
              App Development Services
            </h1>
            <p className="pt-2 md:px-12 text-gray-500">
              At GetToPeaks, we provide comprehensive app development services, including iOS, Android, and cross-platform development. Our team of expert developers ensures seamless user experiences by focusing on performance, security, and scalability. We cover the entire development process—from initial concept and design to coding, testing, and launch. Beyond functionality, our app development services also include UI/UX design, ensuring that your app is not only functional but also visually appealing and easy to navigate.
            </p>
          </div>
        </div>
      </div>

      {/* Functionality and Features Section */}
      <div className="text-start mb-12">
        <h1 className="text-[#1a5252] uppercase text-3xl font-bold mb-4">
          Functionality and Features
        </h1>
        <p className="pt-3 text-gray-500 sm:pr-56">
          Our apps are built to serve a wide range of business needs. Some key functionalities we offer include:
        </p>
        <div className="pt-8">
          <p className="text-gray-500">
            <span className="font-bold text-black">• E-commerce Integration:</span>
            Allowing businesses to sell products and services directly through their app.
          </p>
          <p className="pt-4 text-gray-500">
            <span className="font-bold text-black">• Push Notifications:</span>
            Keeping users engaged and informed about new offers or updates.
          </p>
          <p className="pt-4 text-gray-500">
            <span className="font-bold text-black">• Real-Time Data and Analytics:</span>
            Enabling businesses to track user behavior and app performance.
          </p>
          <p className="pt-4 text-gray-500">
            <span className="font-bold text-black">• Custom APIs:</span>
            Providing seamless integration with existing business systems or third-party applications.
          </p>
          <p className="pt-4 text-gray-500">
            <span className="font-bold text-black">• Security Features:</span>
            Ensuring top-notch encryption and data protection for all user transactions and information.
          </p>
        </div>
      </div>

      {/* Digital Marketing Section */}
      <div>
        <h1 className="text-[#1a5252] text-3xl font-bold mb-4">
          The Role of Digital Marketing in App Development
        </h1>
        <p className="pt-8 text-gray-500">
          An important aspect of the success of an app development project is its digital marketing. This is, of course, included in
          <span className="text-[#1a5252] font-bold"> GetToPeaks </span>
          integration with digital marketing strategies throughout the development phase! Marketing is important to get your app in front of its target audience since it drives downloads and user engagement. We use digital marketing to make your app more visible, be it through in-app advertisements or social media promotions. Moreover, we utilize SEO strategies tailored for app content, helping apps rank higher in app stores and search engines. Paid advertising, influencer marketing, and targeted email campaigns further amplify the reach and effectiveness of your app. Through these efforts,
          <span className="text-[#1a5252] font-bold"> GetToPeaks </span>
          ensures that your app stands out in a crowded marketplace and drives continuous growth for your business.
        </p>
      </div>

      {/* Flutter in App Development Section */}
      <div className="pt-10 text-center">
        <h1 className="text-[#1a5252] text-3xl font-bold mb-4">
          Flutter in App Development
        </h1>
        <p className="pt-8 text-gray-500 text-start">
          <span className="text-[#1a5252] font-bold">Flutter</span> is a powerful open-source framework developed by Google that allows for building natively compiled applications for mobile, web, and desktop from a single codebase. At GetToPeaks, we leverage Flutter in our
          <span className="text-[#1a5252] font-bold"> app development services</span>
          to create high-performance, visually appealing apps for both
          <span className="text-[#1a5252] font-bold"> iOS</span> and
          <span className="text-[#1a5252] font-bold"> Android</span> platforms.
        </p>
        <p className="pt-8 text-gray-500 text-start">
          Flutter's unique
          <span className="text-[#1a5252] font-bold"> cross-platform capabilities</span>
          enable us to develop apps faster and more efficiently, reducing time-to-market while maintaining quality. Its
          <span className="text-[#1a5252] font-bold"> Hot Reload</span> feature allows developers to instantly see changes in the app, which speeds up the development process. Flutter also provides
          <span className="text-[#1a5252] font-bold"> beautiful pre-built widgets</span>
          and customizable components that ensure seamless UI/UX design across different devices.
        </p>
        <p className="pt-8 text-gray-500 text-start">
          One of the key benefits of using Flutter is its ability to deliver
          <span className="text-[#1a5252] font-bold"> native-like performance</span>
          while saving costs, as only one codebase is required for multiple platforms. This makes Flutter an ideal choice for businesses looking for
          <span className="text-[#1a5252] font-bold"> cost-effective app development</span>
          without sacrificing performance or user experience.
        </p>
        <p className="pt-8 text-gray-500 text-start">
          With Flutter, GetToPeaks ensures that our clients receive
          <span className="text-[#1a5252] font-bold"> high-quality, scalable, and responsive applications</span>
          that meet their business goals and provide users with an engaging experience.
        </p>
      </div>
    </div>
  );
};

export default AppAdvantage;
