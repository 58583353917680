import React from "react";

const SEOAdvantage = () => {
  // Service list data
  const services = [
    {
      title: "On-Page SEO",
      description:
        "On-page SEO focuses on optimizing the content and structure of your website to make it more search engine-friendly. Our services include:",
      bold: "• Keyword Research:",
      description1:
        "We perform in-depth research to identify the best keywords for your business, ensuring your website targets the right search queries.",
      bold1: "• Content Optimization:",
      description2:
        "We optimize your website’s content by strategically placing keywords, improving readability, and enhancing engagement to help it rank higher in search results.",
      bold2: "• Meta Tags & Descriptions:",
      description3:
        "We create compelling meta titles and descriptions that not only improve SEO but also encourage users to click on your site from search engine results pages.",
      bold3: "• Internal Linking:",
      description4:
        "We ensure your website has a logical structure by adding internal links that help search engines crawl your site more effectively and improve user experience.",
    },
    {
      title: "Off-Page SEO",
      description:
        "Off-page SEO focuses on building your website’s authority and credibility through external factors. Our off-page SEO services include:",
      bold: "• Link Building:",
      description1:
        "We acquire high-quality backlinks from trusted websites, improving your site’s domain authority and search engine ranking.",
      bold1: "• Social Signals:",
      description2:
        "We leverage social media platforms to increase your brand’s visibility and create engagement that drives traffic to your website.",
      bold2: "• Local SEO:",
      description3:
        "We help businesses improve their presence in local search results by optimizing Google My Business profiles and acquiring local citations.",
    },
    {
      title: "Technical SEO",
      description:
        "A well-optimized website structure is essential for SEO success. Our technical SEO services ensure that your website runs smoothly and is easy for search engines to crawl and index. We focus on:",
      bold: "• Website Speed Optimization:",
      description1:
        "We optimize your site’s loading time to ensure fast performance, which is a key ranking factor for search engines.",
      bold1: "• Mobile Optimization:",
      description2:
        "With more users accessing the web via mobile devices, we ensure your website is fully responsive and mobile-friendly.",
      bold2: "• Sitemap & Robots.txt:",
      description3:
        "We create and submit XML sitemaps and optimize your robots.txt file to help search engines navigate your site efficiently.",
      bold3: "• Fixing Crawl Errors:",
      description4:
        "We identify and fix issues such as broken links, duplicate content, and other errors that may affect your site’s SEO performance.",
    },
    {
      title: "Local SEO",
      description:
        "For businesses targeting a specific geographic area, local SEO is crucial. We focus on optimizing your website and online presence to rank better in local searches, helping potential customers find you easily. This includes:",
      bold: "• Google My Business Optimization:",
      description1:
        "We optimize your Google My Business profile to ensure it ranks high in local searches and map results.",
      bold1: "• Local Citations:",
      description2:
        "We build consistent and accurate local citations across directories and platforms to improve your local search visibility.",
      bold2: "• Local Keyword Targeting:",
      description3:
        "We research and implement location-specific keywords to ensure you’re attracting customers in your area.",
    },
    // {
    //     title: "E-Commerce SEO",
    //     description: "For online stores, our e-commerce SEO services ensure that your product pages rank higher and drive more sales. We optimize your product listings, improve site architecture, and ensure a smooth user experience that keeps customers coming back."
    // },
    // {
    //     title: "SEO Audits",
    //     description: "Not sure where your website stands in terms of SEO? Our comprehensive SEO audits will analyze every aspect of your website, identifying areas for improvement. From technical issues to content gaps, we provide actionable insights to boost your SEO performance."
    // }
  ];

  // Advantages data
  const advantages = [
    {
      title: "Proven Results:",
      description:
        "We have a track record of helping businesses improve their search engine rankings, drive traffic, and increase conversions.",
    },
    {
      title: "Customized Strategies:",
      description:
        "Every business is different, and we tailor our SEO strategies to meet your specific needs and goals. Whether you are looking to target a local audience or a global market, we create a plan that works for you.",
    },
    {
      title: "White Hat Techniques:",
      description:
        "We use ethical, white-hat SEO techniques that follow search engine guidelines, ensuring long-term success without risking penalties.",
    },
    {
      title: "Continuous Improvement:",
      description:
        "SEO is an ongoing process, and we’re committed to continuously monitoring and improving your website’s performance. We provide regular reports and updates, so you always know how your SEO efforts are paying off.",
    },
    {
      title: "Affordable Pricing:",
      description:
        "We offer cost-effective SEO services that deliver high-quality results without breaking your budget.",
    },
  ];
  

  // Get to Peaks advantages data
  const getToPeaksAdvantages = [
    {
      title: "Data-Driven Approach:",
      description:
        "We rely on data and analytics to inform our SEO strategies, ensuring that every decision we make is backed by insight and evidence.",
    },
    {
      title: "Experienced Team:",
      description:
        "Our SEO experts have years of experience optimizing websites for businesses in a wide range of industries.",
    },
    {
      title: "Clear Communication:",
      description:
        "We believe in transparency, providing you with regular updates, reports, and insights into your SEO campaign's progress.",
    },
    {
      title: "Comprehensive Services:",
      description:
        "From technical SEO and on-page optimization to content creation and link building, we offer a complete range of SEO services to cover all aspects of your website's performance.",
    },
  ];
  
  return (
    <div className="bg-gray-100 pb-14 px-6 md:pb-24 md:px-12 lg:px-16">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className="text-[#1a5252] uppercase pt-6 text-3xl font-bold mb-4">
          Why is SEO Important?
        </h1>
        <div className="flex flex-col lg:flex-row py-4 gap-5">
          <img
            className="w-full lg:w-1/3 sm:rounded-tr-full"
            src="https://img.freepik.com/premium-photo/seo-search-engine-optimisation-digital-marketing-business-technology-concept_218381-38532.jpg?ga=GA1.2.994116845.1714564752&semt=ais_hybrid"
            alt="WordPress Website Design"
          />
          <p className="text-start flex justify-center items-center text-gray-400">
            Search Engine Optimization (SEO) is the process of optimizing your
            website to achieve higher rankings on search engine results pages
            (SERPs) like Google. With the majority of online experiences
            beginning with a search, appearing on the first page of results is
            vital for your business's success and online presence . SEO not only
            enhances your website’s visibility but also builds trust with your
            audience, establishes your credibility as an industry leader, and
            drives highly targeted traffic to your site. This targeted traffic
            translates into more leads, higher sales, and increased revenue,
            fueling sustainable business growth.
          </p>
        </div>
      </div>

      {/* Our WordPress Services Section */}
      <div className="text-start mb-12">
        <h1 className="text-[#1a5252] uppercase text-3xl font-bold mb-4">
          Our SEO Services Include:
        </h1>

        {/* Service List */}
        {services.map((service, index) => (
          <div key={index} className="pt-10">
            <h2 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold pb-1">
              {index + 1}. {service.title}
            </h2>
            <p className="py-3 text-gray-400">{service.description}</p>
            <div className="">
              <p className="pt-8 text-gray-500">
                <span className="font-bold text-black"> {service.bold}</span>{" "}
                {service.description1}
              </p>
              <p className="pt-8 text-gray-500">
                <span className="font-bold text-black"> {service.bold1}</span>{" "}
                {service.description2}
              </p>
              <p className="pt-8 text-gray-500">
                <span className="font-bold text-black"> {service.bold2}</span>{" "}
                {service.description3}
              </p>
             
            </div>
            
          </div>
        ))}
         <h2 className="pt-10 text-[#1a5252] uppercase text-xl md:text-2xl font-bold pb-1">5. E-Commerce SEO </h2>
              <p className="pt-8 text-gray-500">
                For online stores, our e-commerce SEO services ensure that your
                product pages rank higher and drive more sales. We optimize your
                product listings, improve site architecture, and ensure a smooth
                user experience that keeps customers coming back.
              </p>
              <h2 className="pt-10 text-[#1a5252] uppercase text-xl md:text-2xl font-bold pb-1">6. SEO Audits </h2>
              <p className="pt-8 text-gray-500">
                Not sure where your website stands in terms of SEO? Our
                comprehensive SEO audits will analyze every aspect of your
                website, identifying areas for improvement. From technical
                issues to content gaps, we provide actionable insights to boost
                your SEO performance.
              </p>
      </div>

      {/* Why Choose Us Section */}
      <div>
        <h1 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold mb-4">
        Why Choose Get to Peaks for SEO Services in the UK?
        </h1>
        <div className="sm:pr-64">
          {advantages.map((advantage, index) => (
            <p key={index} className="text-lg text-start pt-2 text-gray-500">
              <span className="font-bold text-black">• {advantage.title}</span>{" "}
              {advantage.description}
            </p>
          ))}
        </div>

        {/* The Get to Peaks Advantage Section */}
        <h1 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold mb-4 pt-10">
          The Get to Peaks Advantage
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 text-center gap-4">
          {getToPeaksAdvantages.map((advantage, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg p-4">
              <p className="font-bold text-black text-xl">{advantage.title}</p>
              <p className="text-base text-gray-500">{advantage.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SEOAdvantage;
