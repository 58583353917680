import React from "react";
import GDAbout from "./GraphicDesignAbout"
import GDBox from "./GraphicDesignBox";
import GDAdvantage from "./GraphicDesignAdvantage";
import GDPricing from "./GraphicDesignPricing";

const GD = () => {
  return (
    <>
      <div
        className="text-center bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEi-tYq1BQQ-g0wZ2FQ1wcqd4oAj9xuMGyc0t0e5_SlKVxA6vzhvRor2-9gi9Flah7u7bLcim6iugaGhBSzVKYUl82zt4WBWmnhWZsIp_J2fXYGldpjIngdY_HTvXprsweB429P3jvcclVOhEFjqpOArKUlsj0ndT0Dd37XTz369HPX2MCsgFLzCnVbT71Xh/w1684-h1069-p-k-no-nu/APP%20(1).png")`,
          backgroundSize: "cover", // Ensures the image covers the entire container
          backgroundPosition: "center",
          backgroundAttachment: "fixed", // Keeps the image fixed while scrolling
          minHeight: "100vh", // Ensures the container is at least full viewport height
          backgroundRepeat: "no-repeat", // Prevents repeating the image
        }}
      >
        <div className="flex flex-col items-center justify-center min-h-screen">
          <h1 className="text-4xl sm:text-6xl font-bold pt-28 text-white">Graphic Design</h1>
          <p className="sm:text-xl pt-1 px-6 sm:px-56 text-white">
          A picture speaks a thousand words. Our graphic designers create striking visuals that not only represent your brand but also leave a lasting impression on your audience.
          </p>
        </div>
      </div>
    <GDPricing />
    <GDAbout />
    <GDBox />
    {/* <GDAdvantage /> */}
    </>
  );
};

export default GD;
