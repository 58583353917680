import React from "react";

const BlogPage = () => {
  return (
    <>
      <div
        className="text-center bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiNd5xI91kCC25mKymipEE9w8HzZsv3hq6CRZYB25leRn09MHScH6KqcKylC8JxisgO66YE_jZXXeVkBvX29_ZIgnBib5qHOXM5UOI8S3ltOiFlmgPnenSQw-nmljdIdtQuDuNihxhqQOzxETJRtlyIsXhV_UsywrWfl_FIAq_9BuR6le_Ct9O_PD_WP0B4/w554-h312/APP%20(9).png")`,
          backgroundSize: "cover", // Ensures the image covers the entire container
          backgroundPosition: "center",
          backgroundAttachment: "fixed", // Keeps the image fixed while scrolling
          minHeight: "100vh", // Ensures the container is at least full viewport height
          backgroundRepeat: "no-repeat", // Prevents repeating the image
        }}
      >
        <div className="flex flex-col items-center justify-center min-h-screen">
          <h1 className="text-6xl font-bold pt-28 text-white">BLOG</h1>
          <p className="text-lg sm:text-3xl pt-1 sm:px-56 text-white">
            Explore our latest insights and expert opinions. Stay informed on
            industry trends and best practices.
          </p>
        </div>
      </div>
      <h1 className="text-5xl sm:text-6xl bg-gray-200 text-[#1a5252] font-bold flex justify-center items-center py-72">
        Comming Soon
      </h1>
      {/* <BlogCards /> */}
    </>
  );
};

export default BlogPage;
