import React from "react";
import { Link } from "react-router-dom";

const BusinessNeed = () => {
  // Keyframes for underline animation
  const keyframesStyle = `
    @keyframes underlineAnimation {
      0% { width: 0; }
      50% { width: 100%; }
      100% { width: 0; }
    }
  `;

  return (
    <div className="py-14 px-4 md:py-32 md:px-24">
      {/* Embed CSS for animation */}
      <style>{keyframesStyle}</style>

      <div className="text-center">
        <h1
          id="our"
          className="text-[#1a5252] text-4xl md:text-4xl pb-1 font-bold relative inline-block"
        >
          GET TO PEAKS
          <span
            className="block absolute bottom-0 left-0 h-[4px] bg-[#1a5252]"
            style={{
              width: "0",
              animation: "underlineAnimation 4s infinite",
              transform: "translateY(4px)", // Adjust this if needed
            }}
          ></span>
        </h1>
        <p className="pt-3 text-lg text-gray-400">Grow Your Business with Get To Peaks.</p>
      </div>

      <div className="mt-6 flex flex-col md:flex-row shadow-2xl border-l-8 border-[#1a5252] rounded-r-2xl">
        <div className="w-full md:w-[70%] p-6 md:p-14">
          <h1 className="text-2xl md:text-4xl font-bold leading-8 md:leading-10">
            We'll give you a quotation according to your{" "}
            <span className="text-[#1a5252]">Business Needs.</span>
          </h1>
          <p className="text-gray-500 pt-6 md:pt-10">GET TO PEAKS</p>
        </div>
        <div className="w-full md:w-[30%] flex justify-center md:block pt-4 md:pt-28 pl-0 md:pl-5 pb-6 md:pb-0">
          <Link
            to="contact"
            className="bg-[#1a5252] text-white font-semibold py-3 px-8 rounded-full transition-transform duration-300 hover:scale-105"
          >
            Book a Call
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BusinessNeed;
