import React from "react";
import SEOAdvantage from "../SEO/SEOAdvantage"
const SEOAbout = () => {
  return (
    <>
      <div className="pt-14 flex flex-col-reverse md:flex-row justify-around px-4 md:pt-24 md:px-10 bg-slate-100">
        {/* Embed CSS for animation */}

        <div className="w-full md:w-[49%] flex  flex-col justify-center text-start pt-2">
          <h1 className="text-[#1a5252] uppercase text-4xl md:text-4xl pb-1 font-bold relative inline-block">
            SEO
          </h1>
          <p className="py-6 text-gray-400 ">
          At Get to Peaks, we provide professional SEO services in the UK that help businesses enhance their search engine rankings, drive organic traffic, and significantly boost conversions. In today’s competitive digital landscape, your business needs to stand out and be easily found by potential customers. Our expert SEO strategies are tailored to achieve this—ensuring your website is seen by the right audience, at the right time, leading to sustainable growth and higher visibility online.
          </p>
        </div>

        <div className="w-full md:w-[49%] p-3 mb-6 md:mb-0">
          <div className="grid grid-cols-1 gap-4">
            <img
              className="rounded-2xl w-full sm:h-[300px]"
              src="https://img.freepik.com/free-photo/top-view-tools-marketing_1134-83.jpg?ga=GA1.2.994116845.1714564752&semt=ais_hybrid"
              alt="Top image"
            />
          </div>
        </div>
      </div>
      <SEOAdvantage />
    </>
  );
};

export default SEOAbout;
