import React from "react";

const Business = () => {
  return (
    <section className="flex flex-col-reverse md:flex-row justify-around items-center px-4 py-16 md:py-20 md:px-10 bg-slate-100 gap-5">
      {/* Text content section */}
      <div className="flex flex-col justify-center items-start w-full pt-2 relative">
        <h1 className="text-[#1a5252] text-lg md:text-3xl font-extrabold uppercase relative pb-2 sm:pb-4">
          Are you ready to transform your business?
        </h1>
        
        <p className="text-sm sm:text-base text-gray-500 sm:py-8">
          Transform your business with <strong>Get to Peaks</strong>. We specialize in innovative solutions like custom web development, SEO, and digital marketing to help businesses stand out online. With our expertise, you can enhance your website’s functionality, improve search engine rankings, and attract more organic traffic. Our services streamline business operations with tailored app development, ensuring your company stays efficient and competitive in the digital landscape. Elevate your business with Get to Peaks! Our dedicated team works closely with you to understand your unique business needs and deliver solutions that drive real results.
        </p>
      </div>

      {/* Image section */}
      <div className="w-full">
        <img
          className="rounded-2xl w-full"
          src="https://img.freepik.com/free-photo/man-suit-standing-office-with-clipboard-pointing-poster-with-words_1098-17121.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
          alt="Man in suit pointing at poster"
        />
      </div>
    </section>
  );
};

export default Business;
