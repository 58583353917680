import React from "react";
import SMMAdvantage from "./SMMAdvantage";
// import ShopifyStoreAdvantage from "./ShopifyStoreAdvantage";

const SMMAbout = () => {
  return (
    <>
      <div className="pt-14 flex flex-col-reverse md:flex-row justify-around px-4 md:pt-24 md:px-10 bg-slate-100">
        {/* Embed CSS for animation */}

        <div className="w-full md:w-[49%] flex  flex-col justify-center text-start pt-2">
          <h1 className="text-[#1a5252] uppercase text-4xl md:text-4xl pb-1 font-bold relative inline-block">
          Social Media Management
          </h1>
          <p className="py-6 text-gray-400 ">
          At Get to Peaks, we understand that social media is one of the most powerful tools to connect with your audience and grow your business. Our Social Media Marketing 2024 Services are designed to help you build a strong online presence, engage your target market, and drive meaningful results across all major platforms.Whether you're looking to enhance brand awareness, generate leads, or boost conversions, we tailor strategies that align with your goals. Let us help you stay ahead of the competition with innovative campaigns, data-driven insights, and a dedicated approach to maximizing your social media impact.
          </p>
        </div>

        <div className="w-full md:w-[49%] p-3 mb-6 md:mb-0">
          <div className="grid grid-cols-1 gap-4">
            <img
              className="rounded-2xl w-full sm:h-[300px]"
              src="https://img.freepik.com/free-photo/medium-shot-men-holding-smartphone_23-2150208243.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
              alt="Top image"
            />
          </div>
        </div>
      </div>
      <SMMAdvantage />
    </>
  );
};

export default SMMAbout;
