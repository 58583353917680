import React from "react";
import Experience from "../Components/AboutPageFile/Experience";
import Work from "../Components/AboutPageFile/Work";
import Transform from "../Components/AboutPageFile/OurMission";
import ServicesCards from "../Components/ServicesPageFile/ServicesCards";

const ServicesPage = () => {
  return (
    <>
      {/* Hero Section */}
      <div
        className="text-center flex flex-col items-center justify-center min-h-screen bg-fixed bg-cover bg-center"
        style={{
          backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjkQTj2bR0SYkYi9XnmWQruLeDk1FeT6-4csB_4Hf4tppamRBwH2OBnb9VmIiXDtgo13SrgIw70UiHRBJtBYjnS7t_GnLPnYh_zK27rfts3yQkfyUc2aAqwtSviCm9XOx8rzXEjfa1dKDTXprDDx7ggr0lOlKRYIo-_umPgJweM1AcbwRlqQXf4bZQWgz8B/w624-h351/APP%20(4).png")`,
        }}
      >
        <div className="py-28 px-4 sm:px-56 text-white text-center">
          <h1 className="text-4xl sm:text-5xl font-bold">Our Digital Marketing Services</h1>
          <p className="text-lg sm:text-xl mt-2">
            We offer a comprehensive range of services designed to boost your online presence and drive your business forward.
          </p>
        </div>
      </div>

      {/* Services Section */}
      <section className="px-4 sm:px-4 py-12 bg-gray-100">
        <ServicesCards />
      </section>
    </>
  );
};

export default ServicesPage;
