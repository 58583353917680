import React from "react";
import AppAdvantage from "./AppAdvantage";

const AppDevelopmentAbout = () => {
  return (
    <>
      <div className="pt-14 flex flex-col-reverse md:flex-row justify-around px-4 md:pt-24 md:px-10 bg-slate-100">
        <div className="w-full md:w-[49%] flex flex-col justify-center text-start pt-2">
          <h1 className="text-[#1a5252] uppercase text-4xl pb-1 font-bold relative inline-block">
            App Development
          </h1>
          <p className="py-6 text-gray-400">
            Conceptualized in the States, GetToPeaks is a cutting-edge comprehensive digital business. App Development is one of our main services along with all the other types. We develop powerful, custom applications for iOS and Android with specific appeal to companies of all sizes. Whether it is a small business with an appetite for inventive apps or enterprise behemoths that need to elevate user engagement, we develop tailored solutions that fulfill the respective organizational objectives.
          </p>
        </div>

        <div className="w-full md:w-[49%] p-3 mb-6 md:mb-0">
          <img
            className="rounded-2xl w-full sm:h-[300px] object-cover"
            src="https://img.freepik.com/free-photo/mobile-phone-app-streaming-music_53876-95812.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
            alt="App Development"
          />
        </div>
      </div>
      <AppAdvantage />
    </>
  );
};

export default AppDevelopmentAbout;
