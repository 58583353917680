import React from "react";
import WordpressDevelopmentAbout from "./WordpressDevelopmentAbout";
import WordpressDevelopmentBox from "./WordpressDevelopmentBox";
// import WordpressAdvantage from "../WebDevelopmentFile/WebAdvantage";
import WordpressPricing from "./WordpressPricing";

const WordpressDevelopment = () => {
  return (
    <>
      <div
        className="text-center bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgLs7i4QQa__gugEGxiSkY9VyWOlq7_dgkjObrGRjTCFCyA0eICYRDfLZAzwIowcByIxu6f2yhYdtVHjANBlFN6ffRmM1FH1ioD8TNQp4AXW0e8pQBohs0OSoDILn870beDpcZevFM_KEJFGQBfz2S8sOyzxUBrLW0WTLMcwlL2to0sG5JeqMV7alVwHACL/w1684-h1069-p-k-no-nu/Untitled%20design.png")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          minHeight: "100vh",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex flex-col items-center justify-center min-h-screen">
          <h1 className="text-3xl sm:text-6xl font-bold pt-28 text-white">Wordpress Development</h1>
          <p className="sm:text-xl pt-1 px-6 sm:px-56 text-white">
            Harness the power of WordPress with our professional development services. We build dynamic, scalable, and easy-to-manage WordPress websites that empower you to take control of your content.
          </p>
        </div>
      </div>
      <WordpressPricing />
      <WordpressDevelopmentAbout />
      <WordpressDevelopmentBox />
      {/* <WordpressAdvantage /> */}
    </>
  );
};

export default WordpressDevelopment;
