import React from "react";

const WebDevelopmentAbout = () => {
  return (
    <div className="py-14 flex flex-col-reverse md:flex-row justify-around px-5 md:py-24 md:px-8 bg-slate-100">
      <div className="w-full md:w-[100%] text-start">
        <h1 className="text-[#1a5252] uppercase text-2xl md:text-4xl pb-1 font-bold">
          WEB DEVELOPMENT
        </h1>
        <img
          className="rounded-2xl w-full h-[70vh] object-cover py-10"
          src="https://img.freepik.com/free-photo/programming-background-with-person-working-with-codes-computer_23-2150010144.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
          alt="Top image"
        />
        <h1 className="text-[#1a5252] uppercase text-2xl md:text-2xl pb-1 font-bold">
          Affordable Web Development Customized To Your Needs
        </h1>
        <p className="py-3 text-gray-400 sm:pr-20">
          At Get to Peaks, we specialize in delivering Web Development solutions
          tailored to your business needs. Whether you need a sleek, responsive
          website or a robust e-commerce platform, we craft sites that not only
          look stunning but also deliver a seamless user experience. Our
          approach combines cutting-edge technology with creative design to help
          your business thrive online.
        </p>
        <p className="py-2 pb-8 text-gray-400">
          We ensure that every website we build is optimized for speed,
          security, and scalability, providing your customers with a smooth,
          uninterrupted browsing experience. By staying ahead of industry trends
          and best practices, we empower businesses to stand out from the
          competition and build a lasting digital presence. Our team is
          dedicated to delivering websites that meet today’s standards and are
          future-proof, ensuring long-term success.
        </p>

        {/* Advantages of Web Development Services */}
        <div>
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="w-full sm:pl-2 pt-8 sm:pt-0">
              <p className="text-xl sm:text-2xl font-bold uppercase text-[#1a5252]">
                Advantages of Web Development Services
              </p>
              <p className="py-2 sm:py-6 text-sm sm:text-base text-gray-500">
                Web development services offer numerous benefits that can
                significantly impact a business’s online presence and overall
                success. From creating a professional digital identity to
                ensuring seamless functionality, here are the key advantages of
                investing in web development services:
              </p>
            </div>
            <div className="w-full md:mb-0">
              <img
                className="w-full h-[20vh] object-cover rounded-lg"
                src="https://img.freepik.com/free-photo/design-html-web-design-template-concept_53876-120438.jpg?ga=GA1.2.994116845.1714564752&semt=ais_hybrid"
                alt="Web Design Concept"
              />
            </div>
          </div>

          {/** Advantages List */}
          {[
            {
              title: "Customized Website Design",
              description: `One of the most important advantages of web development services is the ability to create a website that is fully customized to meet the specific needs of a business. Rather than relying on pre-made templates, web developers can design unique, branded websites that reflect the company’s identity and goals. This tailored approach helps businesses stand out in a competitive online space.`,
            },
            {
              title: "Enhanced User Experience",
              description: `Web development services focus on creating websites that are user-friendly and easy to navigate. A well-structured website with intuitive functionality ensures that visitors can quickly find the information or products they need. Enhanced user experience not only improves customer satisfaction but also increases the chances of conversions and repeat visits.`,
            },
            {
              title: "Responsive Design for All Devices",
              description: `With the rise of mobile browsing, having a website that is responsive and adaptable to various devices is crucial. Professional web development services ensure that websites function smoothly on smartphones, tablets, and desktops. A responsive design guarantees accessibility to all users, providing a consistent experience across platforms.`,
            },
            {
              title: "SEO Optimization",
              description: `Web development services often include search engine optimization (SEO) as part of their offering. A well-coded website with optimized performance will rank higher in search engine results. This boosts visibility, driving more organic traffic to the site, and ultimately leading to increased sales or engagement.`,
            },
            {
              title: "Security and Reliability",
              description: `Investing in web development services ensures that your website is built with top-notch security measures. Professional developers integrate robust security protocols to protect sensitive information and defend against cyber threats. A well-developed website is also more reliable and less prone to crashes or technical issues.`,
            },
            {
              title: "Scalability for Future Growth",
              description: `Web development services are designed to accommodate business growth. As your company expands, so do your website needs. Whether it’s adding new features, handling increased traffic, or integrating with other systems, custom-built websites are scalable, allowing for smooth updates without major disruptions.`,
            },
            {
              title: "Ongoing Support and Maintenance",
              description: `Web development services don’t stop at just creating a website. Most professional developers offer ongoing support and maintenance to ensure the website remains up-to-date, secure, and functional. This saves businesses time and resources, ensuring that their online platform runs smoothly over time.`,
            },
          ].map((advantage, index) => (
            <div key={index} className="pt-10">
              <h1 className="text-[#1a5252] uppercase text-xl md:text-2xl pb-1 font-bold">
                {index + 1}. {advantage.title}
              </h1>
              <p className="py-3 text-gray-400 sm:pr-20">
                {advantage.description}
              </p>
            </div>
          ))}

          {/* Call to Action (Optional) */}
          {/* 
          <button className="bg-[#1a5252] text-white font-semibold mt-2 py-2 px-5 rounded-full">
            Book a Call
          </button> 
          */}
        </div>
      </div>
    </div>
  );
};

export default WebDevelopmentAbout;
