import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import AboutPage from "./Pages/AboutPage";
import ServicesPage from "./Pages/ServicesPage";
import BlogPage from "./Pages/BlogPage";
import ContactPage from "./Pages/ContactPage";
import Home from "./Home";
import WebDevelopment from "./ServicesCradsFile/WebDevelopmentFile/WebDevelopment";
import AppDevelopment from "./ServicesCradsFile/AppDevelopment/AppDevelopment";
import WordpressDevelopment from "./ServicesCradsFile/WordpressDevelopment/WordpressDevelopment";
import SEO from "./ServicesCradsFile/SEO/SEO"
import GD from "./ServicesCradsFile/Graphic Design/GraphicDesign";
import SMM from "./ServicesCradsFile/SMM/SMM";
import ShopifyStore from "./ServicesCradsFile/ShopifyStore/ShopifyStore";
import VirtualAssistant from "./ServicesCradsFile/VirtualAssistant/VirtualAssistant"
import VideoEditing from "./ServicesCradsFile/VideoEditing/VideoEditing"
import PricingButton from "./Components/PricingButton";
import Package from "./Components/Package";
import ReactGA from "react-ga"


ReactGA.initialize('G-M7XZ1KB84R')

ReactGA.send({
  hitType:"pageview",
  page:window.location.pathname,
});
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} /> 
        <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/pricingbutton" element={<PricingButton />} />
        <Route path="/webdevelopment" element={<WebDevelopment />} />
        <Route path="/appdevelopment" element={<AppDevelopment />} />
        <Route path="/wordpressdevelopment" element={<WordpressDevelopment />} />
        <Route path="/seo" element={<SEO />} />
        <Route path="/graphicdesign" element={<GD />} />
        <Route path="/socialmedia" element={<SMM />} />
        <Route path="/shopifystore" element={<ShopifyStore />} />
        <Route path="/virtualassistant" element={<VirtualAssistant />} />
        <Route path="/videoediting" element={<VideoEditing />} />
        <Route path="/package" element={<Package />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
