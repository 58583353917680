import React from 'react';

const WebBox = () => {
  return (
    <div className="w-full bg-[#1a5252] flex justify-center items-center flex-col pt-12 px-4 md:px-8 text-center relative">
      {/* Embed CSS for animation */}
      <style>
        {`
          @keyframes underline-animation {
            0% { width: 0; }
            50% { width: 100%; }
            100% { width: 0; }
          }
          .underline-animation {
            width: 0;
            height: 4px;
            background-color: #ffffff;
            position: absolute;
            bottom: -2px; /* Adjust for better positioning */
            left: 50%;
            transform: translateX(-50%);
            animation: underline-animation 8s infinite;
          }
        `}
      </style>

      <h1
        id="our"
        className="text-white text-xl sm:text-4xl pb-2 sm:pb-4 font-bold relative inline-block"
      >
        Transform Your Brand Online with Innovative Web Development
        <span
          className="block underline-animation"
        ></span>
      </h1>

      <p className="text-white text-sm md:text-lg md:px-0 lg:px-0 pt-2 pb-5">
        In the digital world, having a strong online presence is essential for any business to thrive. By combining cutting-edge web development techniques with a robust digital marketing strategy in the USA, you can transform your brand and captivate your audience. Our expert team designs websites that not only look stunning but are also highly functional, fast, and SEO optimized to enhance user experience and drive traffic. Let us help you stand out with a website that elevates your brand and delivers real results.
      </p>

      <a
        href="https://wa.me/+447999568729"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <button className="bg-white text-[#1a5252] font-bold py-2 px-6 md:px-8 rounded-full">
          Contact Us
        </button> */}
      </a>
    </div>
  );
};

export default WebBox;
