import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa'; // Importing tick and cross icons

const AppPricing = () => {
  const plans = [
    { 
      title: 'Basic', 
      price: '$800', 
      subtitle: 'Mobile app design and development for 3-5 screens in Flutter', 
      features: [ 
        { text: 'Functional Android app', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'Functional iOS App', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'Source code', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'App icon', icon: <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'Splash screen', icon: <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'App submission', icon: <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'App Design', icon: <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" /> }, 
      ], 
      buttonText: 'Get Started', 
      buttonLink: '/basic-plan', 
      bgColor: 'bg-white', 
      textColor: 'text-gray-800', 
    }, 
    { 
      title: 'Standard', 
      price: '$1000', 
      subtitle: 'Mobile app design and development for 5-7 complex screens in Flutter', 
      features: [ 
        { text: 'Functional Android app', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'Functional iOS App', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'Source code', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'App icon', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'Splash screen', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'App submission', icon: <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'App Design', icon: <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" /> }, 
      ], 
      buttonText: 'Get Started', 
      buttonLink: '/standard-plan', 
      bgColor: 'bg-gray-100', 
      textColor: 'text-gray-800', 
    }, 
    { 
      title: 'Premium', 
      price: '$1200', 
      subtitle: 'I\'ll build a complete app for your business with at least 15 to 18 screens', 
      features: [ 
        { text: 'Functional Android app', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'Functional iOS App', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'Source code', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'App icon', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'Splash screen', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'App submission', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
        { text: 'App Design', icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" /> }, 
      ], 
      buttonText: 'Get Started', 
      buttonLink: '/premium-plan', 
      bgColor: 'bg-white', 
      textColor: 'text-gray-800', 
    }, 
  ];

  return (
    <div className="py-8">
      <h2 className="text-4xl font-bold text-center mb-8">Pricing Plan</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-10">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`${plan.bgColor} ${plan.textColor} shadow-lg border-2 border-[#1a5252] rounded-3xl p-4 flex flex-col transition-transform duration-300 transform hover:scale-105`} 
          >
            <h3 className="text-2xl font-bold mb-1">{plan.title}</h3>
            <div className="flex-grow">
              <p className="text-2xl font-bold mb-1">{plan.price}</p>
              <p className="text-sm text-gray-500 mb-3">{plan.subtitle}</p>
              <ul className="text-sm flex-grow p-0 m-0">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center mb-2">
                    {feature.icon}
                    <span className="ml-2">{feature.text}</span>
                  </li>
                ))}
              </ul>
            </div>
            <a
              href="https://wa.me/+447999568729"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-[#1f6969] text-white font-bold py-2 rounded-full mt-4 text-center transition duration-300 hover:bg-[#1a5252]"
            >
              {plan.buttonText}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppPricing;
