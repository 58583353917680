import React from 'react';
import {
  FaTasks,
  FaPencilRuler,
  FaCode,
  FaDatabase,
  FaClipboardCheck,
  FaWrench,
  FaLock,
} from 'react-icons/fa'; // Importing icons

const advantages = [
  {
    title: 'Planning and Requirement Analysis',
    description:
      'Understanding project goals and gathering detailed information from stakeholders for clear website objectives.',
    icon: FaTasks,
  },
  {
    title: 'Design and Prototyping',
    description:
      'Creating wireframes and prototypes that define the site’s layout, visuals, and functionality.',
    icon: FaPencilRuler,
  },
  {
    title: 'Front-End Development',
    description:
      'Building the user interface (UI) using HTML, CSS, and JavaScript to ensure proper design and behavior.',
    icon: FaCode,
  },
  {
    title: 'Back-End Development',
    description:
      'Setting up server-side infrastructure, including databases, server management, and API integration.',
    icon: FaDatabase,
  },
  {
    title: 'Integration and Testing',
    description:
      'Performing rigorous testing for functionality, compatibility, and performance across various platforms.',
    icon: FaClipboardCheck,
  },
  {
    title: 'Maintenance and Updates',
    description:
      'Providing ongoing maintenance to ensure security, updates, and proper website functionality after launch.',
    icon: FaWrench,
  },
  {
    title: 'Secure and Robust Websites',
    description:
      'Implementing security protocols to safeguard against threats like hacking, malware, and data breaches.',
    icon: FaLock,
  },
];

const AdvantageCard = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center text-center p-6 bg-white rounded-lg shadow-lg">
    <Icon className="text-[#1a5252] text-3xl mb-4" />
    <h3 className="text-gray-800 font-semibold mb-2">{title}</h3>
    <p className="text-gray-700">{description}</p>
  </div>
);

const WebDevelopmentAdvantage = () => (
  <div className="pb-14 px-6 md:pb-24 md:px-12 lg:px-16 gap-4 bg-gray-100">
    <div className="text-center mb-12">
      <h1 className="text-[#1a5252] text-3xl md:text-4xl font-bold mb-4">
        Main Process of Web Development
      </h1>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {advantages.map((advantage, index) => (
        <AdvantageCard
          key={index}
          icon={advantage.icon}
          title={advantage.title}
          description={advantage.description}
        />
      ))}
    </div>
  </div>
);

export default WebDevelopmentAdvantage;
