import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa"; // Importing tick and cross icons
import { Link } from "react-router-dom";

const PackagePricing = () => {
  const plans = [
    {
      title: "Basic",
      price: "$650",
      subtitle:
        "Responsive Landing Page Design + 4 Sections Included + CTAs, Forms (Contact Me for a Custom Offer)",
      features: [
        {
          text: "(1 page) Business Website",
          icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />,
        },
        {
          text: "SEO 5 keywords",
          icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />,
        },
        {
          text: "Social Media Weekly Posting (5 posts, 2 Reels)",
          icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />,
        },
        {
          text: "Graphic Design",
          icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />,
        },
        {
          text: "Full Time Support",
          icon: (
            <FaTimes className="bg-red-500 text-white p-1 rounded-full text-lg" />
          ),
        },
      ],
      buttonText: "Get Started",
      buttonLink: "https://wa.me/+447999568729",
      bgColor: "bg-white",
      textColor: "text-gray-800",
    },
    {
      title: "Standard",
      price: "$800",
      subtitle:
        "Kickstarter + Design/Redesign Up to 5 Pages + Responsive + Speed Optimized + Free Support",
      features: [
        {
          text: "(3 pages) Business Website",
          icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />,
        },
        {
          text: "SEO 10 keywords",
          icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />,
        },
        {
          text: "Social Media Weekly Posting (8 posts, 4 Reels)",
          icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />,
        },
        {
          text: "Graphic Design",
          icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />,
        },
        {
          text: "Full Time Support",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
      ],
      buttonText: "Get Started",
      buttonLink: "https://wa.me/+447999568729",
      bgColor: "bg-gray-100",
      textColor: "text-gray-800",
    },
    {
      title: "Premium",
      price: "$1200",
      subtitle:
        "Premium Design/Redesign Up to 8 Pages + Speed Optimized + eCommerce + Free Support",
      features: [
        {
          text: "(5 pages) Business Website",
          icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />,
        },
        {
          text: "SEO 15 keywords",
          icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />,
        },
        {
          text: "Social Media Weekly Posting (12 posts, 6 Reels)",
          icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />,
        },
        {
          text: "Graphic Design",
          icon: <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />,
        },
        {
          text: "Full Time Support",
          icon: (
            <FaCheck className="bg-green-500 text-white p-1 rounded-full text-lg" />
          ),
        },
      ],
      buttonText: "Get Started",
      buttonLink: "https://wa.me/+447999568729",
      bgColor: "bg-white",
      textColor: "text-gray-800",
    },
  ];

  return (
    <div className="py-8">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-10">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`${plan.bgColor} ${plan.textColor} shadow-lg border-2 border-[#1a5252] rounded-3xl p-4 flex flex-col transition-transform duration-300 transform hover:scale-105`}
          >
            <h3 className="text-2xl font-bold mb-1">{plan.title}</h3>
            <div className="flex-grow">
              <p className="text-2xl font-bold mb-1">{plan.price}</p>
              <p className="text-sm text-gray-500 mb-3">{plan.subtitle}</p>
              <ul className="text-sm flex-grow p-0 m-0">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center mb-2">
                    {feature.icon}
                    <span className="ml-2">{feature.text}</span>
                  </li>
                ))}
              </ul>
            </div>
            <Link
              to={plan.buttonLink} 
              target="_blank"// Changed href to to for React Router
              className="bg-[#1f6969] text-white font-bold py-2 rounded-full mt-4 text-center transition duration-300 hover:bg-[#1a5252]"
            >
              {plan.buttonText}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PackagePricing;
