import React from "react";
import SMMPricing from "./SMMPricing";
import SMMAbout from "./SMMAbout";
import SMMBox from "./SMMBox";
// import SMMAdvantage from "./SMMAdvantage";


const SMM = () => {
  return (
    <>
      <div
        className="text-center bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEheV69pewjdXE2tiRdlX5Eb18Ml3zPVauoUcQFMvfk0jn3Gs0I7kLDMJobBmu0QHLNA-FTrDSTFLP0Xyvi96ZtYq5O4KGXsF5tGovU4Vy18AccSevU2QQboQHfM59J-JQCTxCJTJiNJ1OQuXQqiwwnzZq2PjEeoek4HDAqYgv94WtdegQNoWWwLy7rBD5b7/w1684-h1069-p-k-no-nu/APP.png")`,
          backgroundSize: "cover", // Ensures the image covers the entire container
          backgroundPosition: "center",
          backgroundAttachment: "fixed", // Keeps the image fixed while scrolling
          minHeight: "100vh", // Ensures the container is at least full viewport height
          backgroundRepeat: "no-repeat", // Prevents repeating the image
        }}
      >
        <div className="flex flex-col items-center justify-center min-h-screen">
          <h1 className="text-3xl sm:text-6xl font-bold pt-28 text-white">Social Media Management</h1>
          <p className="sm:text-xl pt-1 px-6 sm:px-56 text-white">
          Our Social Media Management boosts your brand’s visibility through content creation, audience engagement, and performance tracking on major platforms.
          </p>
        </div>
      </div>
    <SMMPricing />
    <SMMAbout />
    <SMMBox />
    {/* <SMMAdvantage /> */}
    </>
  );
};

export default SMM;
