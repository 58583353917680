import React from "react";
import { FaCheck } from "react-icons/fa"; // Importing check icon

const ShopifyStoreAdvantage = () => {
  // Service list data
  const services = [
    // Added Shopify-related services
    {
      title: "Custom Shopify Store Design",
      description:
        "Your online store is a reflection of your brand. We create custom-designed Shopify stores that align with your business’s identity, ensuring a cohesive and engaging user experience. Our design services include:",
      bold: "• Bespoke Themes:",
      description1:
        "Tailored Shopify themes that stand out from the competition, optimized for your industry and target audience.",
      bold1: "• Responsive Design:",
      description2:
        "Ensuring that your store looks great and functions smoothly on all devices, from desktops to mobile phones.",
      bold2: "• UX/UI Optimization:",
      description3:
        "Crafting intuitive navigation and layouts that guide your customers seamlessly through the buying process.",
    },
    {
      title: "Shopify Store Setup and Configuration",
      description:
        "Getting your Shopify store up and running can be complex, but we simplify the process by handling all the technical aspects. Connecting your store to your custom domain for a professional online presence. Payment Gateway Configuration Setting up secure and reliable payment options such as Shopify Payments, PayPal, and credit card processors. Shipping and Tax Setup Configuring shipping rates and taxes based on your business location and customer preferences.",
    },
    {
      title: "Product Catalog Management",
      description:
        "We help you organize and manage your product listings efficiently, ensuring customers have a smooth shopping experience. Our services include:",
      bold: "• Product Upload and Editing:",
      description1:
        "Adding product descriptions, images, prices, and variants to your store.",
      bold1: "• Inventory Management:",
      description2:
        "Setting up real-time inventory tracking to avoid overselling and ensure smooth stock management.",
      bold2: "• SEO for Product Pages:",
      description3:
        "Optimizing product titles, descriptions, and metadata to improve search engine visibility and drive traffic.",
    },
    {
      title: "Shopify App Integration",
      description:
        "Enhance your store’s functionality with Shopify's vast range of apps. We recommend and integrate the right tools for your business, such as:",
      bold: "• Marketing and Sales Apps:",
      description1:
        "Automating email campaigns, customer follow-ups, and retargeting to boost sales.",
      bold1: "• Customer Support Tools:",
      description2:
        "Implementing live chat, helpdesk, and feedback tools to improve customer service.",
    },
    {
      title: "Shopify Store Migration",
      description:
        "If you're moving from another e-commerce platform to Shopify, we ensure a smooth migration process with minimal downtime.",
      bold: "• Data Migration:",
      description1:
        "Transferring product listings, customer data, and order histories from your existing platform to Shopify.",
      bold1: "• SEO Preservation:",
      description2:
        "Preserving your SEO rankings and ensuring that existing links and URLs remain intact post-migration.",
    },
    {
      title: "Shopify Maintenance and Support",
      description:
        "Once your Shopify store is live, ongoing maintenance is crucial to ensure it performs optimally. At Get to Peaks, we provide regular store updates to keep your Shopify platform running smoothly with the latest features and security patches.",
      bold: "• Store Updates:",
      description1:
        "Regular updates to keep your store running smoothly with the latest Shopify features and security patches.",
      bold1: "• Performance Monitoring:",
      description2:
        "Tracking your store’s performance to identify and fix any speed or technical issues.",
      bold2: "• Ongoing Support:",
      description3:
        "Offering continuous support for any technical questions or store-related issues you encounter.",
    },
  ];

  // Advantages data
  const advantages = [
    {
      title: "Expert Editors: ",
      description:
        "Our team of professional video editors brings creativity, technical expertise, and attention to detail to every project.",
    },
    {
      title: "Customized Solutions: ",
      description:
        "We tailor our editing services to fit your specific goals, whether it’s creating promotional content, training videos, or engaging social media clips.",
    },
    {
      title: "High-Quality Output: ",
      description:
        "We deliver videos that not only look visually stunning but also align with your brand’s voice and messaging.",
    },
    {
      title: "Fast Turnaround: ",
      description:
        "We work efficiently to meet your deadlines without compromising on quality, ensuring your projects are completed on time.",
    },
    {
      title: "Comprehensive Service: ",
      description:
        "From concept development to the final cut, we provide end-to-end video editing solutions that meet your business needs.",
    },
  ];

  return (
    <div className="bg-gray-100 pb-14 px-6 md:py-24 md:px-12 lg:px-16">
      {/* Header Section */}
      <div className="text-center mb-12">
        <div className="flex flex-col  w-[100%] lg:flex-row py-4 gap-5 ">
          <img
            className="w-full  lg:w-96  sm:rounded-tr-full  "
            src="https://img.freepik.com/free-photo/online-shopping-cart-e-commers-concept_53876-127436.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
            alt="WordPress Website Design"
          />
          <div>
            <h1 className="text-[#1a5252] uppercase text-4xl md:text-4xl pb-1 font-bold relative inline-block">
              Why Choose Shopify for Your E-Commerce Store?
            </h1>
            <p className=" md:px-12 text-gray-500">
              Shopify is one of the leading e-commerce platforms, trusted by
              millions of businesses worldwide. With its intuitive interface,
              extensive customization options, and powerful tools for managing
              products, payments, and shipping, Shopify provides everything you
              need to run a successful online business. At Get to Peaks, we
              leverage Shopify’s capabilities to build stores that are not only
              visually appealing but also highly functional and
              conversion-focused.
            </p>
          </div>
        </div>
      </div>

      {/* Our video editing Services Section */}
      <div className="text-start mb-12">
        <h1 className="text-[#1a5252] uppercase text-3xl font-bold mb-4">
          Our Shopify Store Services Include:
        </h1>

        {/* Service List */}
        {services.map((service, index) => (
          <div key={index} className="pt-10">
            <h2 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold pb-1">
              {index + 1}. {service.title}
            </h2>
            <p className="pt-3 text-gray-500 sm:pr-56">{service.description}</p>
            <div className="">
              <p className="pt-8 text-gray-500">
                <span className="font-bold text-black"> {service.bold}</span>{" "}
                {service.description1}
              </p>
              <p className="pt-8 text-gray-500">
                <span className="font-bold text-black"> {service.bold1}</span>{" "}
                {service.description2}
              </p>
              <p className="pt-8 text-gray-500">
                <span className="font-bold text-black"> {service.bold2}</span>{" "}
                {service.description3}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Why Choose Us Section */}
      <div className="sm:pr-56">
        <h1 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold mb-4">
          Why Choose Get to Peaks for Shopify Store Services?
        </h1>
        <p className="text-gray-500">
          At Get to Peaks, we offer a convincing range of Shopify Store
          Services, backed by a team of certified Shopify professionals with
          deep expertise in building and optimizing online stores. Our end to
          end service ensures that every aspect of your Shopify store is
          covered, from initial design and development to ongoing post launch
          support. Whether you are a small business or a large enterprise, we
          provide scalable solutions that can grow with your needs. With a
          proven track record of delivering results driven Shopify stores, we
          are committed to maximizing sales and enhancing customer satisfaction.
          Our customer centric approach means we take the time to truly
          understand your business, ensuring your Shopify store aligns with your
          specific goals and provides a seamless, engaging shopping experience
          for your customers.
        </p>
      </div>
    </div>
  );
};

export default ShopifyStoreAdvantage;
