import React from 'react';

const Reached = () => {
  return (
    <section className="w-full bg-[#1a5252] flex flex-col items-center justify-center py-12 px-4 md:px-8 text-center">
      {/* Inline CSS for underline animation */}
      <style>
        {`
          @keyframes underlineAnimation {
            0% { width: 0; }
            50% { width: 100%; }
            100% { width: 0; }
          }
          .underline {
            width: 0;
            height: 4px;
            background-color: #ffffff;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            animation: underlineAnimation 8s infinite;
          }
        `}
      </style>

      <h1 className="text-white text-xl sm:text-4xl font-bold pb-4 relative">
        We provide you the best service
        <span className="underline"></span>
      </h1>
      
      <p className="text-white text-sm md:text-lg md:px-40 lg:px-60 pt-2 pb-5">
        Digital marketing encompasses a broad range of online strategies and activities aimed at promoting and branding products, services, or businesses using digital channels. It leverages the power of the internet and electronic devices to reach and engage with a target audience.
      </p>

      <a
        href="https://wa.me/+447999568729"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="bg-white text-[#1a5252] font-bold py-2 px-6 md:px-8 rounded-full transition duration-300 ease-in-out hover:bg-[#1a5252] hover:text-white">
          Contact Us
        </button>
      </a>
    </section>
  );
};

export default Reached;
