import React from "react";
import { FaCheck } from "react-icons/fa"; // Importing check icon

const VideoEditingAdvantage = () => {
  // Service list data
  const services = [
    {
      title: "Corporate and Promotional Video Editing",
      description: "We specialize in editing high-quality corporate videos that communicate your brand message clearly and professionally. Our services include:",
      bold: "• Brand Storytelling:",
      description1: "Crafting a compelling narrative that aligns with your company’s values and goals.",
      bold1: "• Product Demos:",
      description2: "Highlighting your products or services in an informative and visually appealing way.",
      bold2: "• Event Recaps:",
      description3: "Editing footage from corporate events, trade shows, or conferences into engaging summaries that showcase key moments."
    },
    {
      title: "Social Media Video Editing",
      description: "In 2024, short-form video content is essential for engaging social media users. Our team creates attention-grabbing videos for platforms like Instagram, TikTok, and Facebook, offering:",
      bold: "• Reels, Stories, and TikToks:",
      description1: "Editing videos optimized for each platform, ensuring maximum reach and engagement.",
      bold1: "• Viral Video Content:",
      description2: "Crafting shareable videos that reflect current trends while aligning with your brand’s identity.",
      bold2: "• Motion Graphics and Effects:",
      description3: "Adding dynamic graphics, transitions, and effects that make your videos stand out in a crowded feed."
    },
    {
      title: "YouTube and Vlog Editing",
      description: "Our editors help content creators and brands optimize their YouTube presence with:",
      bold: "• Professional Vlog Editing:",
      description1: "Transforming raw vlog footage into engaging, well-paced videos that keep your audience watching.",
      bold1: "• SEO Optimization:",
      description2: "Structuring your videos for better visibility on YouTube search results and recommended feeds.",
      bold2: "• Thumbnails and Titles:",
      description3: "Designing compelling video thumbnails and titles that boost click-through rates and attract more viewers."
    },
    {
      title: "Training and Educational Videos",
      description: "We provide video editing services for businesses and educators looking to produce professional training materials. Our services include:",
      bold: "• Instructional Videos:",
      description1: "Organizing and editing footage for clear, concise training videos.",
      bold1: "• On-Screen Graphics and Animations:",
      description2: "Incorporating visuals like text overlays, diagrams, and animations to enhance learning.",
      bold2: "• Multi-Camera Editing:",
      description3: "Syncing multiple camera angles for dynamic, professional-looking educational videos."
    },
    {
      title: "Video Subtitling and Captioning",
      description: "In 2024, accessibility is key. We offer video subtitling and captioning services to make your content accessible to a wider audience, including:",
      bold: "• Closed Captions:",
      description1: "Adding accurate subtitles for viewers with hearing impairments or those watching without sound.",
      bold1: "• Multilingual Subtitles:",
      description2: "Providing translations for global audiences to expand your reach and engagement."
    },
    {
      title: "Advanced Post-Production Services",
      description: "We go beyond simple editing to offer comprehensive post-production services, including:",
      bold: "• Color Correction and Grading:",
      description1: "Enhancing the visual appeal of your footage by adjusting colors and tones for a professional finish.",
      bold1: "• Sound Editing and Mixing:",
      description2: "Ensuring clear, high-quality audio with synchronized voiceovers, background music, and sound effects.",
      bold2: "• Special Effects and Animation:",
      description3: "Adding animations, motion graphics, and visual effects to take your videos to the next level."
    }
  ];
  // Advantages data
  const advantages = [
    {
      title: "Expert Editors: ",
      description:
        "Our team of professional video editors brings creativity, technical expertise, and attention to detail to every project.",
    },
    {
      title: "Customized Solutions: ",
      description:
        "We tailor our editing services to fit your specific goals, whether it’s creating promotional content, training videos, or engaging social media clips.",
    },
    {
      title: "High-Quality Output: ",
      description:
        "We deliver videos that not only look visually stunning but also align with your brand’s voice and messaging.",
    },
    {
      title: "Fast Turnaround: ",
      description:
        "We work efficiently to meet your deadlines without compromising on quality, ensuring your projects are completed on time.",
    },
    {
      title: "Comprehensive Service: ",
      description:
        "From concept development to the final cut, we provide end-to-end video editing solutions that meet your business needs.",
    },
  ];



  return (
    <div className="bg-gray-100 pb-14 px-6 md:pb-24 md:px-12 lg:px-16">
      {/* Header Section */}
      <div className="text-center mb-12">
        <h1 className="text-[#1a5252] uppercase pt-6 text-3xl font-bold mb-4">
          Why Video Editing Matters in uk
        </h1>
        <div className="flex flex-col  w-[100%] lg:flex-row py-4 gap-5 ">
          <img
            className="w-full  lg:w-96  sm:rounded-tr-full  "
            src="https://img.freepik.com/free-photo/asian-video-editor-working-with-multimedia-footage-editing-film-montage-with-color-grading-sound-working-freelance-home-using-post-production-software-make-movie_482257-48024.jpg?ga=GA1.1.994116845.1714564752&semt=ais_hybrid"
            alt="WordPress Website Design"
          />
          <p className="text-start flex justify-center items-center text-gray-400">
            Video content continues to dominate digital marketing in 2024, with
            platforms like YouTube, Instagram, and TikTok becoming central to
            brand visibility. Professionally edited videos enhance your
            storytelling, keep viewers engaged, and leave a lasting
            impression.From eye-catching transitions to seamless cuts, our
            services ensure your videos stand out from the competition. Whether
            it's for promotional campaigns, social media, or corporate needs,
            we're here to help you deliver high-quality content. Our video
            editing services are designed to help you leverage the power of
            video content to increase engagement, boost conversions, and grow
            your brand.
          </p>
        </div>
      </div>

      {/* Our video editing Services Section */}
      <div className="text-start mb-12">
        <h1 className="text-[#1a5252] uppercase text-3xl font-bold mb-4">
          Our Video Editing Services Include:
        </h1>

        {/* Service List */}
        {services.map((service, index) => (
          <div key={index} className="pt-10">
            <h2 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold pb-1">
              {index + 1}. {service.title}
            </h2>
            <p className="pt-3 text-gray-400 sm:pr-56">{service.description}</p>
            <div className="">
            <p className="pt-8 text-gray-400">
              <span className="font-bold text-black"> {service.bold}</span>{" "}
              {service.description1}
            </p>
            <p className="pt-8 text-gray-400">
              <span className="font-bold text-black"> {service.bold1}</span>{" "}
              {service.description2}
            </p>
            <p className="pt-8 text-gray-400">
              <span className="font-bold text-black"> {service.bold2}</span>{" "}
              {service.description3}
            </p>
            </div>
          </div>
        ))}
      </div>

      {/* Why Choose Us Section */}
      <div>
        <h1 className="text-[#1a5252] uppercase text-xl md:text-2xl font-bold mb-4">
        Why Choose Get to Peaks for Video Editing Services?
        </h1>
        <div className="sm:pr-64">
          {advantages.map((advantage, index) => (
            <p key={index} className="text-lg text-start pt-2 text-gray-500">
              <span className="font-bold text-black">• {advantage.title}</span>{" "}
              {advantage.description}
            </p>
          ))}
        </div>

       
      </div>
    </div>
  );
};

export default VideoEditingAdvantage;
