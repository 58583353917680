import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagramSquare,
  FaYoutube,
  FaPinterestSquare,
  FaTwitterSquare,
  FaLinkedin,
} from "react-icons/fa";

const Footer = () => {
  // Scroll to the top of the page when a link is clicked
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="w-full flex flex-col md:flex-row bg-[#1a5252] py-16 px-6 gap-7">
      {/* About Us Section */}
      <div className="w-full md:w-[40%]">
        <h1 className="font-bold text-white text-2xl">About Us</h1>
        <p className="text-white text-base pt-2">
          Welcome to GettoPeaks! We are a digital marketing agency that helps
          businesses grow online. Our team focuses on SEO, social media
          marketing, and content creation to boost traffic, engagement, and
          sales.
        </p>
      </div>

      {/* Useful Links Section */}
      <div className="w-full md:w-[30%]">
        <h1 className="font-bold text-white text-2xl">Useful Links</h1>
        <Link
          to="/"
          className="text-white text-base pt-2 font-semibold block"
          onClick={handleLinkClick}
        >
          HOME
        </Link>
        <Link
          to="/about"
          className="text-white text-base pt-2 font-semibold block"
          onClick={handleLinkClick}
        >
          ABOUT
        </Link>
        <Link
          to="/services"
          className="text-white text-base pt-2 font-semibold block"
          onClick={handleLinkClick}
        >
          SERVICES
        </Link>
        <Link
          to="/blog"
          className="text-white text-base pt-2 font-semibold block"
          onClick={handleLinkClick}
        >
          BLOG
        </Link>
        <Link
          to="/contact"
          className="text-white text-base pt-2 font-semibold block"
          onClick={handleLinkClick}
        >
          CONTACT
        </Link>
      </div>

      {/* Social Media Section */}
      <div className="w-full md:w-[30%]">
        <h1 className="font-bold text-white text-2xl pb-4">Social Media</h1>
        <div className="flex gap-6">
          <a
            href="https://www.instagram.com/get_to_peaks/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="text-white text-3xl font-semibold" />
          </a>
          <a
            href="https://www.instagram.com/get_to_peaks/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagramSquare className="text-white text-4xl font-semibold" />
          </a>
          <a
            href="https://www.youtube.com/@GETTOPEAKS"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube className="text-white text-4xl font-semibold" />
          </a>
          <a
            href="https://www.pinterest.com/Get_To_peaks/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaPinterestSquare className="text-white text-4xl font-semibold" />
          </a>
          <a
            href="https://www.linkedin.com/company/get-to-peaks/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin className="text-white text-4xl font-semibold" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
